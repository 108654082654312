import * as React from 'react';
import {useState, useEffect} from 'react';
import {Person, PropertyProjectionN, AccountBalance, Budget, Person2, PropertyProjection} from '../types';
import { convertGrossToNet, calculate_help_tax, calculate_stamp_duty } from '../TaxFunctions';
import PropertyProjectionTable from './PropertyProjectionTable';
import PropertyProjectionInputs from './PropertyProjectionTableInputs';


const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];


function CalculateInvestmentGrowthForPeriod( accounts: AccountBalance[], originalBudget: Budget, people2: Person2[],
  numberOfYears: number, yearlyGrowth: number, 
  incomeTax: any, help_tax: any, propertyValue: number,
  loanInterestRate: number, government_25_using: boolean, focusHomebuyer: boolean) :PropertyProjection {

  let budget: Budget = JSON.parse(JSON.stringify(originalBudget));

  let propertyProjectionN : PropertyProjectionN[] = [];
  let propertyProjectionN12 : PropertyProjectionN[] = [];

  let property_value_init = propertyValue;
  let property_value = propertyValue;
  let property_value_share = 1;
  let loan_value = 0;
  let loan_term_in_years = 30;


  // NAV = 5% capital improved value
  // rates = 2.4565 cents for NAV dollar
  let rates = property_value_init*0.05*0.024565;

  // not sure what these are...?
  let fee = 86.50 + 2.34*(property_value_init/1000);
  if ( fee>3609)
      fee = 3609;
  fee += 112.40;

  let stamp_duty = calculate_stamp_duty(property_value_init, true)

  let government_25_eligibility = false;
  if (property_value_init <= 950000)
    government_25_eligibility = true;
  else
  {
    government_25_eligibility = false;
    government_25_using = false;
  }

  if (government_25_using)
  {
    property_value_share = 0.75;
  }

  let currentAssets = 0;
  let totalExpenses = 0;
  let totalIncome = 0;
  let totalBudget = 0;
  let offset = 0;
  

  accounts.forEach(acct=>{
    currentAssets += acct.value;
  });

  budget.budgetCategories.push({id: -1, date: new Date(), category: "Rates" , amount: -1*rates, frequency: "annually", person: "", wasChanged: false, budget: 0});
  budget.budgetCategories.push({id: -1, date: new Date(), category: "Insurance" , amount: 1000, frequency: "annually", person: "", wasChanged: false, budget: 0});

  for (let x = 0; x < budget.budgetCategories.length; x++)
  if (budget.budgetCategories[x].category === "Rent")
  {
    budget.budgetCategories[x].amount = 0;
  }

  budget.budgetCategories.forEach((budgetCat) =>{
    if (budgetCat.amount <= 0)
    {
      totalExpenses += Number(budgetCat.amount);
    }
    else
    {
      if (budgetCat.person != "")
      {
        people2.forEach(person=>{
          if (person.name === budgetCat.person)
          {
            totalIncome += convertGrossToNet(Number(budgetCat.amount), incomeTax, person.helpDebt, help_tax);
          }
        }); 
      }
    }
  });
  totalBudget = totalIncome + totalExpenses;
  console.log(totalExpenses);

  currentAssets -= (Number(stamp_duty) + Number(fee));
  let initial_principal = 0;

  if (government_25_using)
  {
    if (focusHomebuyer)//prio homebuyers find
    {
      //min 5%
      currentAssets -= property_value_init*0.05;

      initial_principal = 0.7 * property_value_init;
      let hbf = 0.25 * property_value_init;

      if (hbf < currentAssets)
      {
        currentAssets -= hbf;
        property_value_share = 1;
      }
      else
      {
        property_value_share = 0.75 + ((currentAssets / property_value_init));
      }
    }
    else
    {
      initial_principal = property_value_init * 0.75 - Number(currentAssets);
    }
      
  }
  else
  {
    initial_principal = Number(property_value_init) - Number(currentAssets);
  }
   


  let deposit_percentage = currentAssets/property_value_init;

  let interest_rate_per_period = loanInterestRate/12;
  let total_number_of_payments = loan_term_in_years*12;
  let P = initial_principal;
  let r = interest_rate_per_period;
  let n = total_number_of_payments;
  let payment_amount_per_period = P*(r*(1+r)**n)/((1+r)**n - 1);
  let yearly_payment = payment_amount_per_period*12;


  let housing_percentage_of_budget = (Number(yearly_payment))/totalIncome;
  let total_percentage_of_budget_used = 1- (Number(totalBudget) - Number(yearly_payment))  / totalIncome;
  let monthly_loan_payment = yearly_payment/12;
  let growth_increase_perc = yearlyGrowth;

  let yearsToPayOff = -1;

  let emergencyFundLimit = 20000;
  
  //switch to monthly
  totalBudget /= 12;
  yearly_payment /= 12;
  loanInterestRate /= 12;
  growth_increase_perc /= 12;

  for (var i = 0; i < n; i++)
  {  
    let compulsoryPayment = 0;
    let extraPayment = 0;

      if (i === 0)
      {

      }
      else
      {
        compulsoryPayment = yearly_payment;

        if ((totalBudget - compulsoryPayment) > 0)
        {
          if (offset < emergencyFundLimit)
          {
            offset += (totalBudget - yearly_payment); 
          }
          else if (property_value_share < 1)
            offset += (totalBudget - yearly_payment); 
          else
            extraPayment = (totalBudget - yearly_payment);
        }



        if (i === 1)
        {
          property_value = property_value_init;
          loan_value = initial_principal;
        }
        else
        {
          let amtPaidOnLoan;

          if (loan_value > 0)
          {
            loan_value += (propertyProjectionN[i-1].loan_value - offset)*(loanInterestRate);

            if (property_value_share < 1)
            {
              loan_value -= compulsoryPayment;
              amtPaidOnLoan = compulsoryPayment;
              offset += extraPayment;
            }
            else
            {
              loan_value -= (compulsoryPayment + extraPayment);
              amtPaidOnLoan = (compulsoryPayment + extraPayment);
            }

            if (amtPaidOnLoan > loan_value)
            {
              yearsToPayOff = i;// - (1 - (loan_value/amtPaidOnLoan));

              amtPaidOnLoan -= loan_value;
              offset -= loan_value;
              loan_value = 0;
            }


          }
          else
          {
            offset += (compulsoryPayment + extraPayment);
          }

          if (property_value_share < 1 && focusHomebuyer)
          {
            if (offset > propertyProjectionN[i-1].property_value*0.05)
            {
              let percCanPay = offset / propertyProjectionN[i-1].property_value;

              if (percCanPay + property_value_share <= 1)
              {
                offset = 0;
                property_value_share += percCanPay;
              }
              else
              {
                let perWillPay = 1 - property_value_share;
                offset -= perWillPay * propertyProjectionN[i-1].property_value;
                property_value_share = 1;
              }

            }
            
          }
          else if (property_value_share < 1 && !focusHomebuyer)
          {
            if (loan_value <= 0)
            {
              offset -= propertyProjectionN[i-1].property_value*0.25;
              property_value_share += 0.25;
              if (offset < 0)
              {
                loan_value -= offset;
                offset = 0;
              }
                
            }
            else if (loan_value + propertyProjectionN[i-1].property_value*0.25 < offset)
            {
              yearsToPayOff = i;

              offset -= propertyProjectionN[i-1].property_value*0.25;
              property_value_share += 0.25;

              offset -= loan_value;
              loan_value = 0;
            }
            else if (loan_value + propertyProjectionN[i-1].property_value*(1-property_value_share) < offset || loan_value + propertyProjectionN[i-1].property_value*(0.05) < offset)
            {
              let amt = offset - loan_value;
              offset -= amt;
              let sharePaid = amt / propertyProjectionN[i-1].property_value;
              property_value_share += sharePaid;

              if (property_value_share > 1)
              {
                let over = property_value_share - 1;
                offset += over * propertyProjectionN[i-1].property_value;
                property_value_share = 1;
              }

              if (offset > loan_value)
              {
                offset -= loan_value;
                loan_value = 0;
                yearsToPayOff = i;
              }
            }
          } 

          property_value = propertyProjectionN[i-1].property_value*(1+Number(growth_increase_perc));

        }

      }

      propertyProjectionN.push({
        property_value:                     property_value, 
        loan_value:                         loan_value, 
        yearly_payment:                     extraPayment*-1, 
        compulsoryPayment:                  compulsoryPayment*-1,
        offset:                             offset,
        ownedShare:                         Number(property_value_share)
      });





    if (i % 12 === 0)
    {
      propertyProjectionN12.push({
        property_value:                     property_value, 
        loan_value:                         loan_value, 
        yearly_payment:                     extraPayment*-1, 
        compulsoryPayment:                  compulsoryPayment*-1,
        offset:                             offset,
        ownedShare:                         Number(property_value_share)
      });
    }
    
  }

  let propertyProjection: PropertyProjection = {
    projection: propertyProjectionN12,
    budget: totalBudget, 
    yearly_payment: yearly_payment, 
    deposit_percentage: 
    deposit_percentage, 
    stamp_duty: stamp_duty, 
    other_fees: fee, 
    monthly_loan_payment: monthly_loan_payment, 
    monthly_rates: rates, 
    government_25_eligibility: government_25_eligibility, 
    housing_percentage_of_budget: housing_percentage_of_budget,
    total_percentage_of_budget_used: total_percentage_of_budget_used,
    income: totalIncome/12,
    yearsToPayOff: yearsToPayOff/12,
    focusHomebuyerFund: focusHomebuyer
  };

  return propertyProjection;
}


function PropertyApp(props: {accounts: AccountBalance[], budget: Budget, selectedBudget: number, people2: Person2[], investment_budget: {category: string, amount: number}[] ,help_tax_brackets: any[], income_tax_brackets: any[]}) {

  function onPropertyValueChange(event: any)
  {
    setPropertyValue(event.target.value);
    setInvestmentProjection(CalculateInvestmentGrowthForPeriod(props.accounts, props.budget, props.people2, 50, investmentGrowth, props.income_tax_brackets, props.help_tax_brackets, event.target.value, loanInterestRate, government_25_change, focusHomebuyer));
    
  }

  function onSetExtraCash(event: any)
  {
    setExtraCash(event.target.value);
    setInvestmentProjection(CalculateInvestmentGrowthForPeriod(props.accounts, props.budget, props.people2, 50, investmentGrowth, props.income_tax_brackets, props.help_tax_brackets, propertyValue, loanInterestRate, government_25_change, focusHomebuyer));
    
  }

  function onSetLoanInterestRate(event: any)
  {
    setLoanInterestRate(event.target.value/100);
    setInvestmentProjection(CalculateInvestmentGrowthForPeriod(props.accounts, props.budget, props.people2, 50, investmentGrowth, props.income_tax_brackets, props.help_tax_brackets, propertyValue, event.target.value/100, government_25_change, focusHomebuyer));
    
  }

  function onSetInvestmentAnnualIncrease(event: any)
  {
    setInvestmentGrowth(event.target.value);
    setInvestmentProjection(CalculateInvestmentGrowthForPeriod(props.accounts, props.budget, props.people2, 50, event.target.value, props.income_tax_brackets, props.help_tax_brackets, propertyValue, loanInterestRate, government_25_change, focusHomebuyer));
    
  }

  function onSetExtraIncomeTowardsLoan(event: any)
  {
    setExtraLoanRepayment(event.target.value/100);
    setInvestmentProjection(CalculateInvestmentGrowthForPeriod(props.accounts, props.budget, props.people2, 50, investmentGrowth, props.income_tax_brackets, props.help_tax_brackets, propertyValue, loanInterestRate, government_25_change, focusHomebuyer));
    
  }

  function onGovernment_25_change(event: any)
  {
    setGovernment_25_change(event.target.checked);
    setInvestmentProjection(CalculateInvestmentGrowthForPeriod(props.accounts, props.budget, props.people2, 50, investmentGrowth, props.income_tax_brackets, props.help_tax_brackets, propertyValue, loanInterestRate, event.target.checked, focusHomebuyer));
  }

  function onFocusHomebuyerChange(event: any)
  {
    setFocusHomebuyer(event.target.checked);
    setInvestmentProjection(CalculateInvestmentGrowthForPeriod(props.accounts, props.budget, props.people2, 50, investmentGrowth, props.income_tax_brackets, props.help_tax_brackets, propertyValue, loanInterestRate, government_25_change, event.target.checked));

  }

  useEffect(() => {
      setInvestmentProjection(CalculateInvestmentGrowthForPeriod(props.accounts, props.budget, props.people2, 50, investmentGrowth, props.income_tax_brackets, props.help_tax_brackets, propertyValue, loanInterestRate, government_25_change, focusHomebuyer));
  }, [props.selectedBudget]);

  const [propertyValue, setPropertyValue] = useState(850000);
  const [extraCash, setExtraCash] = useState(0);
  const [loanInterestRate, setLoanInterestRate] = useState(0.056);
  const [investmentGrowth, setInvestmentGrowth] = useState(0.06);
  const [extraLoanRepayment, setExtraLoanRepayment] = useState(0);
  const [government_25_change, setGovernment_25_change] = useState(true);
  const [focusHomebuyer, setFocusHomebuyer] = useState(true);

  const [investmentProjection, setInvestmentProjection] = useState(CalculateInvestmentGrowthForPeriod(props.accounts, props.budget, props.people2, 50, investmentGrowth, props.income_tax_brackets, props.help_tax_brackets, propertyValue, loanInterestRate, government_25_change, focusHomebuyer));



    return (
      <div className="App">
        <div className="container-fluid">
          <div className="row">

          <div className="col-lg-3">
          </div>
          
          <div className="col-lg-3" style={{position: "fixed"}}>
          <PropertyProjectionInputs setPropertyValue = {onPropertyValueChange} setExtraCash = {onSetExtraCash} setLoanInterestRate = {onSetLoanInterestRate} 
          setInvestmentAnnualIncrease = {onSetInvestmentAnnualIncrease} setExtraIncomeTowardsLoan = {onSetExtraIncomeTowardsLoan} government_25_change={onGovernment_25_change} setFocusHomebuyerFund={onFocusHomebuyerChange} investment_projection={investmentProjection}/>
          </div>

          <div className="col-lg-9">

          <PropertyProjectionTable rowData={investmentProjection} propertyValue={propertyValue}></PropertyProjectionTable>
            
          </div>



          </div>
        </div>
      </div>
    )

  
}

export default PropertyApp;
