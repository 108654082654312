
import * as React from 'react';
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react';
import {useState} from 'react';
//import AddInvestmentRow from './AddInvestmentRow';
import {Transaction, TransactionPeriod, CategorisedTransaction} from '../types';



const UploadTransactionsTable = (props: { 
     transactions : {account:string, transactions: Transaction[]}, noTransDisplayed: number
    }) =>  {
  
    const rows: JSX.Element[] = [];


    if (props.transactions != undefined)
    {

      let n = 0;
      props.transactions.transactions.forEach((r:Transaction) => {
        n++;
        if (n > props.noTransDisplayed)
        return;
        rows.push(
          <tr key = {r.id}>
            <td>{r.date}</td>
            <td>{r.details}</td>
            <td>{r.debit}</td>
            <td>{r.credit}</td>  

          </tr>
        );
      });
    }



    function submit()
    {
      //props.submit();

    }



    return (
      <table className="table table-striped table-bordered table-hover ">
        <thead>
          <tr>
            <th>Date</th>
            <th>Details</th>
            <th>Debit</th>
            <th>Credit</th>
          </tr>
        </thead>
      <tbody>        
          {rows}
    </tbody>
    </table>
    );
  
}

export default UploadTransactionsTable;