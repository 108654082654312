import * as React from 'react';
import {useState, useEffect} from 'react';
import {Person, Person2, InvestmentProjection, InvestmentProjection2, Budget, AccountBalance} from '../types';
import { convertGrossToNet, calculate_help_tax } from '../TaxFunctions';
import InvestmentProjectionTable from './InvestmentProjectionTable';


const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];


// Capital gains (not total investment value)
function CalculateInvestmentGrowth(value: number, yearlyGrowth: number) : number {

  return  value * (yearlyGrowth/100);
}

function GetDateOfLastFinancialYear(years: number) : Date
{
  let currentYear = new Date().getFullYear(); // 2022
  const currentMonth = new Date().getMonth();

  if (currentMonth < 6)
    currentYear += 1;
  
  currentYear -= years;

  return (new Date(currentYear + '-06-30'));
}

function CalculateInvestmentGrowthForPeriod2(accounts: AccountBalance[], budget: Budget, people2: Person2[], incomeTax: any, help_tax: any) :InvestmentProjection2
{
  let projPeople: Person2[] = people2;
  let projExpenses: number;
  let projIncome: number;
  let projIncomeSalaryOnly: number;
  let projValue: number;
  let projInvestmentGrowthNet: number;

  let expenses = 0;


  // Add salary income to people's gross income
  let personGrossIncome: {name: string, income: number}[] = [];
  let personGrossSalaryOnlyIncome: {name: string, income: number}[] = [];
  budget.budgetCategories.forEach((budgetCat) =>{
    if (budgetCat.amount <= 0)
    {
      expenses += Number(budgetCat.amount);
    }
    else
    {
      personGrossIncome.push({name: budgetCat.person, income: Number(budgetCat.amount)});
      personGrossSalaryOnlyIncome.push({name: budgetCat.person, income: Number(budgetCat.amount)});
    }
  });
  projExpenses = expenses;

  // Add investment account income to people's gross income
  let increaseFromInvestment = 0;
  for (let x = 0; x < accounts.length; x++)
  {
    let account = accounts[x];

    if (!account.isInvestment)
      continue;
      
    account.owner.forEach(accOwner => {
      personGrossIncome.forEach(personInc =>{
        if (accOwner.name === personInc.name)
        {
            personInc.income += account.value * 0.1;
            increaseFromInvestment += account.value * 0.1;
        }
      });
    });
  }

  

  // Calculate net income
  let personNetIncome: {name: string, income: number}[] = [];
  let personNetSalaryOnlyIncome: {name: string, income: number}[] = [];
  personGrossIncome.forEach(persIncome =>{
    people2.forEach(person=>{
      if (person.name === persIncome.name)
      {
        personNetIncome.push({name: person.name, income: convertGrossToNet(persIncome.income, incomeTax, person.helpDebt, help_tax)});
      }
    });
  });
  personGrossSalaryOnlyIncome.forEach(persIncome =>{
    people2.forEach(person=>{
      if (person.name === persIncome.name)
      {
        personNetSalaryOnlyIncome.push({name: person.name, income: convertGrossToNet(persIncome.income, incomeTax, person.helpDebt, help_tax)});
      }
    });
  });

  // Calculate sum of income for all people
  let totalNetIncome: number = 0;
  personNetIncome.forEach(persIncome =>{
    totalNetIncome += persIncome.income;
  });
  projIncome = totalNetIncome; 

  // Calculate sum of income for all people only including salary
  let totalNetSalaryOnlyIncome: number = 0;
  personNetSalaryOnlyIncome.forEach(persIncome =>{
    totalNetSalaryOnlyIncome += persIncome.income;
  });
  projIncome = totalNetIncome; 
  projIncomeSalaryOnly = totalNetSalaryOnlyIncome;

  // Calcuate sum of all investment accounts
  let totalAccounts: number = 0;
  accounts.forEach(acct=>{
    if (acct.isInvestment)
      totalAccounts += acct.value;
  });
  projValue = totalAccounts;




  let projection: InvestmentProjection2 = {people: projPeople, expenses: projExpenses, income: projIncome, salaryOnlyIncome:projIncomeSalaryOnly, value: projValue, valueIncreaseFromInvestments: increaseFromInvestment};
  return projection;
}

function CalculateInvestmentGrowthForPeriodsN(accounts: AccountBalance[], budget: Budget, people2: Person2[], incomeTax: any, help_tax: any) : InvestmentProjection2[]
{
  let projectionArray: InvestmentProjection2[] = [];

    // Last FY
    let acctValuesLastFY = 0;
    let acctValuesCurrent = 0;
    accounts.forEach(acct => {
      if (acct.isInvestment)
      {
        acctValuesLastFY += Number(acct.valuelastFY);
        acctValuesCurrent += Number(acct.value);
      }
        
    });

    let lastFyProj: InvestmentProjection2 = {people:people2, expenses:0, income: 0, salaryOnlyIncome: 0, value: acctValuesLastFY, valueIncreaseFromInvestments: 0};

    projectionArray.push(lastFyProj);

    //let currentProj: InvestmentProjection2 = {people:people2, expenses:0, income: 0, salaryOnlyIncome: 0, value: acctValuesCurrent, valueIncreaseFromInvestments: acctValuesCurrent-acctValuesLastFY};
    //currentProj = CalculateInvestmentGrowthForPeriod2(accounts, budget, people2, incomeTax, help_tax);

    //projectionArray.push(currentProj);

  for (let x = 0; x < 20; x++)
  {



    let newAccounts: AccountBalance[] = JSON.parse(JSON.stringify(accounts));
    
    let investmentAccounts = 0;
    for (let y = 0; y < newAccounts.length; y++)
    {
      if (newAccounts[y].isInvestment)
        investmentAccounts++;
    }

    for (let y = 0; y < newAccounts.length; y++)
    {
      if (newAccounts[y].isInvestment)
      {
        newAccounts[y].valuelastFY = newAccounts[y].value;
        newAccounts[y].value +=  (projectionArray[x].income + projectionArray[x].expenses)/investmentAccounts;
      }
      
    }
    accounts = newAccounts;

    if (x ===0)
    {
      let proj: InvestmentProjection2 = CalculateInvestmentGrowthForPeriod2(accounts, budget, people2, incomeTax, help_tax);
      projectionArray.push(proj);
    }
      
    else
      projectionArray.push(CalculateInvestmentGrowthForPeriod2(accounts, budget, people2, incomeTax, help_tax));
    
  }

  


  return projectionArray;
}


function CalculateTotalExpenses(budgetData: Budget) : number {
  let budget = 0;

  budgetData.budgetCategories.forEach((cat)=>{
    if (cat.person === null)
      budget += Number(cat.amount);
  })
  return budget;
}






function InvesmentsApp(props: {people2: Person2[], budget: Budget, accounts: AccountBalance[], investment_budget: {category: string, amount: number}[] , people: Person[], peopleStartFY: Person[], 
  help_tax_brackets: any[], income_tax_brackets: any[], data: any}) {

    const [investmentGrowth, setInvestmentGrowth] = useState(15);
    const [totalExpenses, setTotalExpenses] = useState(CalculateTotalExpenses(props.budget));
    const [investmentProjection, setInvestmentProjection] = useState(CalculateInvestmentGrowthForPeriodsN(props.accounts, props.budget, props.people2, props.income_tax_brackets, props.help_tax_brackets));

    useEffect(() => {
      setTotalExpenses(CalculateTotalExpenses(props.budget));
    }, [props.budget]);

    useEffect(() => {
      setInvestmentProjection(CalculateInvestmentGrowthForPeriodsN(props.accounts, props.budget, props.people2, props.income_tax_brackets, props.help_tax_brackets));
    }, [totalExpenses]);




    return (
      <div className="App">
        <div className="container-fluid">
          <div className="row">

          <div className="col-lg-6">

          <InvestmentProjectionTable investmentProjection={investmentProjection}></InvestmentProjectionTable>
            
          </div>

          <div className="col-lg-6">

            </div>

          </div>
        </div>
      </div>
    )

  
}

export default InvesmentsApp;
