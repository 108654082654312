
import * as React from 'react';
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react';
import {useState} from 'react';
//import AddInvestmentRow from './AddInvestmentRow';
import {Transaction, TransactionPeriod, CategorisedTransaction} from '../types';


const ManageTransactionsTable = (props: { 
     transactions : Transaction[], categories: any[], categorisedTransactions: any, removeTransaction: any, splitTransaction: any, setSplitAmount: any
    }) =>  {

  
    const rows: JSX.Element[] = [];

    const options: JSX.Element[] = [];

    options.push(
      <option value=""></option>
    )
    for (let i = 0; i < props.categories.length; i++)
    {
      options.push(
        
        <option value={props.categories[i].category}>{props.categories[i].category}</option>
      )
    }

    

    function categoriseTransaction(id: number, category: string)
    {
      props.categorisedTransactions.set(id, category);
    }

    if (props.transactions != undefined)
      props.transactions.forEach((r:Transaction) => {

        rows.push(
          <tr key = {r.date + r.details + r.credit + r.debit}>
            <td>{r.date}</td>
            <td>{r.details}</td>
            <td>{r.debit}</td>
            <td>{r.credit}</td>  
            <td><select id = {r.id + ""} onChange={((event) => {categoriseTransaction(r.id, event.target.value)})}>{options}</select></td>
            <td>
            <button onClick={((event) => {props.splitTransaction(r.id, r.details, r.debit, r.credit)})}>Split</button>
            <input size={10} defaultValue={0} onChange={(e) => {props.setSplitAmount(e.target.value)}}></input>
            <button onClick={((event) => {props.removeTransaction(r.id)})}>Remove</button>
            </td>
          </tr>
        );
      });



    return (
      <table className="table table-striped table-bordered table-hover ">
        <thead>
          <tr>
            <th>Date</th>
            <th>Details</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Category</th>
            <th>Edit</th>
          </tr>
        </thead>
      <tbody>        
          {rows}
    </tbody>
    </table>
    );
  
}

export default ManageTransactionsTable;