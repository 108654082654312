import './App.css';
import * as React from 'react';
import MenuItem from './MenuItem';
import * as Bootstrap from 'react-bootstrap'

function MenuBar(props:{changeView: any, active: string, logout: any, reload: any, username: string, password: string}) {

    const menuScreens:string[] = ["Budget", "Investments", "Property", "Add Investment", "Upload Transactions", "Manage Transactions"];
    let rows: JSX.Element[] = [];

    menuScreens.forEach((menuItem) => {
        rows.push(
          <MenuItem key={menuItem} name={menuItem} active={menuItem === props.active ? true : false} onClick={props.changeView}></MenuItem>
        );
      })


  return (

    <Bootstrap.Navbar bg="light" expand="lg">
        <Bootstrap.Container>
            <Bootstrap.Navbar.Brand href="#">Samuel's Finance App</Bootstrap.Navbar.Brand>
                <Bootstrap.Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Bootstrap.Navbar.Collapse id="basic-navbar-nav">
                <Bootstrap.Nav className="me-auto">
                    {rows}
                </Bootstrap.Nav>
            </Bootstrap.Navbar.Collapse>
            <Bootstrap.Button variant="outline-success" onMouseDown={((event) => props.changeView(event, "Reset PW"))}>Reset Password</Bootstrap.Button>
            <Bootstrap.Button variant="outline-success" onMouseDown={props.logout}>Logout</Bootstrap.Button>
            <Bootstrap.Button variant="outline-success" onMouseDown={((event) => props.reload(props.username, props.password))}>R</Bootstrap.Button>
        </Bootstrap.Container>
    </Bootstrap.Navbar>

  );

}

export default MenuBar;
