//import AddInvestmentTable from './AddInvestmentTable';
import * as React from 'react';
import {useState} from 'react';
import {Transaction, TransactionPeriod, CategorisedTransaction as CategorisedTransaction, Data, Period, HelpDebt} from '../types';
import { convertGrossToNet } from '../TaxFunctions';
import ManageTransactionsTable from './ManageTransactionsTable';
import Papa from 'papaparse';
import {format} from 'date-fns';



function ManageTransactionsApp(props: {username: string, password: string, reload: any, unprocessed_transactions: any, budget_categories: any|string[]}) {

  const [error, setError] = useState("");

  function AfterSave(response: any)
  {
    console.log(response);
    if (response!="Data added.")
    {
      setError(response);
    }
    props.reload(props.username, props.password);
  }


  const categorisedTransactions = new Map<number, string>();

  function submitTransactions()
  {
    let data: {id: number, category: string}[] = [];

    categorisedTransactions.forEach((category, id) =>{


      let dataObject : {id: number, category: string} = {
        id: id,
        category: category
      };

      data.push(dataObject);
    });

    addDataToDatabase(data);
  }

  const [succesfullyAdded, setSuccesfullyAdded] = useState(-2);
  const [split_amount, setSplitAmountConst] = useState(0);

  function addDataToDatabase(data: any)
  {
    setSuccesfullyAdded(0);
    const formData:any = new FormData();
    formData.append('username', props.username);
    formData.append('password', props.password);

    formData.append('loadedData', JSON.stringify(data));

    console.log(JSON.stringify(data));

    fetch("https://app.sholmes.org/api_post_categorised_transactions.php", {
      method: "POST",
      redirect: 'follow',
      body: new URLSearchParams(formData)
    })
    .then(function(respo: any) {
      if (respo.ok) {
        setSuccesfullyAdded(1);
      } else (setSuccesfullyAdded(-1));

      return respo;
    })
    .then(resp => resp.json())
    .then(response => AfterSave(response))

  }

  function removeTransaction(id: number)
  {
    const formData:any = new FormData();
    formData.append('username', props.username);
    formData.append('password', props.password);

    formData.append('loadedData', JSON.stringify(id));

  
    fetch("https://app.sholmes.org/api_delete_transaction.php", {
      method: "POST",
      redirect: 'follow',
      body: new URLSearchParams(formData)
    })
    .then(function(respo: any) {
      if (respo.ok) {
        setSuccesfullyAdded(1);
      } else (setSuccesfullyAdded(-1));

      return respo;
    })
    .then(resp => resp.json())
    .then(response => AfterSave(response))

  }

  function splitTransaction(id: number, details: string, debit: number, credit: number)
  {
    const formData:any = new FormData();
    formData.append('username', props.username);
    formData.append('password', props.password);

    let data: {id: number, credit: number, debit: number, split: number} = {id: id, credit: credit, debit: debit, split: split_amount};


    formData.append('loadedData', JSON.stringify(data));

  
    fetch("https://app.sholmes.org/api_split_transaction.php", {
      method: "POST",
      redirect: 'follow',
      body: new URLSearchParams(formData)
    })
    .then(function(respo: any) {
      if (respo.ok) {
        setSuccesfullyAdded(1);
      } else (setSuccesfullyAdded(-1));

      return respo;
    })
    .then(resp => resp.json())
    .then(response => AfterSave(response))
  }

  function setSplitAmount(amount: number)
  {
    setSplitAmountConst(amount);

  }

    return (
      <div className="AddInvestmentApp">
        <div className="container-fluid">
        <div className="row">
          <div className="col col-lg-12">  
            <h3>Transactions:</h3>
            {error != "" &&
            <div>{error}</div>
            }
          </div>
        </div>
        <div className="row">
          <div className="col col-lg-12">  
            <ManageTransactionsTable 
            transactions={props.unprocessed_transactions} 
            categories={props.budget_categories} 
            categorisedTransactions={categorisedTransactions}
            removeTransaction={removeTransaction}
            splitTransaction={splitTransaction}
            setSplitAmount={setSplitAmount}
            ></ManageTransactionsTable>
          </div>
        </div>
        <div className="row">
          <div className="col col-lg-12">
            { (succesfullyAdded === -2) &&
              <button onClick={submitTransactions}>Submit</button>
            }  

            { (succesfullyAdded === -1) &&
              <button onClick={submitTransactions}className = "bg-danger">Submit</button>
            }  

            { (succesfullyAdded === 0) &&
              <button onClick={submitTransactions} className = "bg-warning">Submit</button>
            }  

            { (succesfullyAdded === 1) &&
              <button onClick={submitTransactions} className = "bg-success">Submit</button>
            }  
            
          </div>
        </div>
        </div>
      </div>
    )

  
}

export default ManageTransactionsApp;
