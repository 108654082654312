import AddInvestmentTable from './AddInvestmentTable';
import * as React from 'react';
import {useState} from 'react';
import {Transaction, TransactionPeriod, CategorisedTransaction as CategorisedTransaction, Data, Period, HelpDebt} from '../types';
import { convertGrossToNet } from '../TaxFunctions';
//import BudgetRowTransactions from './budget_row_transactions';




function AddInvestmentApp(props: {data: any, username: string, password: string, reload: any}) {


  const [date, setDate] = useState(0);
  const [audusd, setAUDUSD] = useState(0);
  const [samuel_value, setSamuelValue] = useState(0);
  const [mel_value, setMelValue] = useState(0);
  const [samuel_invested, setSamuelInvested] = useState(0);
  const [samuel_withdrawn, setSamuelWithdrawn] = useState(0);
  const [mel_invested, setMelInvested] = useState(0);
  const [mel_withdrawn, setMelWithdrawn] = useState(0);

  function AfterSave(response: any)
  {
    console.log(response);
    props.reload(props.username, props.password);
  }

  function SubmitNewData()
  {
    const formData:any = new FormData();
    formData.append('username', props.username);
    formData.append('password', props.password);

    formData.append('date', date);
    formData.append('audusd', audusd);
    formData.append('samuel_value', samuel_value);
    formData.append('mel_value', mel_value);
    formData.append('samuel_invested', samuel_invested);
    formData.append('samuel_withdrawn', samuel_withdrawn);
    formData.append('mel_invested', mel_invested);
    formData.append('mel_withdrawn', mel_withdrawn);
      
    fetch("https://app.sholmes.org/api_post_investment_data.php", {
      method: "POST",
      redirect: 'follow',
      body: new URLSearchParams(formData)
    })
    .then(resp => resp.json())
    .then(response => AfterSave(response))
  }



    return (
      <div className="AddInvestmentApp">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-lg-12">   
              <AddInvestmentTable data={props.data} setDate={setDate} setAUDUSD={setAUDUSD} setSamuelValue={setSamuelValue} setMelValue={setMelValue}
              setSamuelInvested={setSamuelInvested} setSamuelWithdrawn={setSamuelWithdrawn} setMelInvested={setMelInvested} setMelWithdrawn={setMelWithdrawn}
              submit={SubmitNewData}
              />
            </div>

          </div>
        </div>
      </div>
    )

  
}

export default AddInvestmentApp;
