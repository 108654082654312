export function convertGrossToNet(gross : number, income_tax: any, help_debt: number, help_tax_brackets: any) : number {
    let tax = 0;
    let tax_bracket = 0;
    let current_income_check = 0;

    //Income tax
    for (var i = 0; i<income_tax.length; i++)
    {
        console.log(income_tax[i].income + " < " + gross);
        if (Number(income_tax[i].income) < gross)
        {
            if (Number(income_tax[i].income) > current_income_check)
            {
                tax_bracket = i;
                current_income_check = Number(income_tax[i].income);
            }
        }

    }
   
    tax = Number(income_tax[tax_bracket].previous_brackets_tax) + Number(income_tax[tax_bracket].extra_tax * (Number(gross) - Number(income_tax[tax_bracket].income)));
    console.log("Income tax: " + tax);
    //Low Tax Offset
    let lto = 0;
    if (gross <=37500)
    lto -= 700;
    if (gross >= 37501 && gross <= 45000)
    lto -= (700 - 0.05*(gross-37500));
    if (gross > 45001 && gross <= 66667)
    lto -= (325-0.015*(gross-45000));

    tax += lto;

    console.log("low tax offset " + lto);

    //Low and Middle Income Tax Offset
    let mdito = 0;
    if (gross <= 37000)
        mdito -= 675;
    if (gross >= 37001 && gross <= 48000)
        mdito -= (675 + 0.075*(gross-37000));
    if (gross >= 48001 && gross <= 90000)
        mdito -= 1500;
    if (gross >= 900001 && gross <= 126000)
        mdito -= (1500 - 0.03*(gross - 90000))

    tax += mdito;

    console.log("low and middle tax offset " + mdito);

    if (tax < 0)
        tax = 0;

    //Medicare 2%
    let medi = 0;
    if (gross > 24276) //for 22/23
     medi+= 0.02*gross;

    tax += medi;
    
    console.log("low and middle tax offset " + medi);

    //Help tax
    let help_tax = calculate_help_tax(gross, help_debt, help_tax_brackets);
    tax+= Number(help_tax);

    console.log("help tax " + help_tax);

    let total_tax = Number(gross)-Number(tax);

    let ret = []
    ret[0] = total_tax;
    ret[1] = help_tax;
    return total_tax;

}

export function calculate_help_tax(gross: number, help_debt: number, help_tax_brackets: any){

    gross = Number(gross);

    //Help tax
    let help_bracket = -1;
    let current_help_check = 0;
    for (var i = 0; i<help_tax_brackets.length; i++)
    {
        
        //if income is bigger than current row
        if (help_tax_brackets[i].income < gross)
        {
            // if biggest found row is smaller than current row
            if (help_tax_brackets[i].income > current_help_check)
            {
                help_bracket = i;
                current_help_check = help_tax_brackets[i].income;
            }
        }

    }

    if (help_bracket == -1)
    {
        
        for (var i = 0; i<help_tax_brackets.length; i++)
        {
            if (help_tax_brackets[i].income == 0)
            {
                help_bracket = i;
            }
        }
    }

    let help_tax = help_tax_brackets[help_bracket].rate * gross;
    if (help_tax > help_debt)
        help_tax = help_debt;


    return help_tax;
}

export function calculate_stamp_duty(property_value: number, first_home_buyer: boolean) : number{

    let stamp_duty = 0;
        if (property_value <= 25000 && property_value >= 0)
            stamp_duty = 0.014*property_value;
        if (property_value <= 130000 && property_value > 25000)
            stamp_duty = 350 + 0.024*(property_value-25000);
        if (property_value <= 960000 && property_value > 130000)
            stamp_duty = 2870 + 0.060*(property_value-130000);
        if (property_value > 960000)
            stamp_duty = 0.055*(property_value);
       

        //if (property_value > 2000000)
        //    stamp_duty = 110000 + 0.065*(property_value-2000000);   
        
        if (first_home_buyer){
            //first home buyer
            if (property_value <= 745000)
                stamp_duty = 38444;
            if (property_value <= 725000)
                stamp_duty = 32141;
            if (property_value <= 700000)
                stamp_duty = 24713;
            if (property_value <= 675000)
                stamp_duty = 17785;
            if (property_value <= 650000)
                stamp_duty = 11356;
            if (property_value <= 625000)
                stamp_duty = 5428;
            if (property_value <= 605000)
                stamp_duty = 1045;
            if (property_value <= 600000)
                stamp_duty = 0;

        }


            return stamp_duty;

}