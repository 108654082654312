
import * as React from 'react';
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react';
import {useState} from 'react';
import {Transaction, TransactionPeriod, CategorisedTransaction, Person, PropertyProjectionN, PropertyProjection} from '../types';
import PropertyProjectionRow from './PropertyProjectionRow';


const PropertyProjectionInputs = (props: {setFocusHomebuyerFund: any , setPropertyValue: any; setExtraCash: any; setLoanInterestRate: any; 
    setInvestmentAnnualIncrease: any; setExtraIncomeTowardsLoan: any; government_25_change:any; investment_projection: PropertyProjection}) =>  {

    return (
      <div className = 'property-inputs'>
            <hr/>
            <div className="row">
                <div className="col-lg-6">
                    Years to Pay Off:
                </div>
                <div className="col-lg-6">
                    {props.investment_projection.yearsToPayOff.toLocaleString('en-AU', {maximumFractionDigits: 2, minimumFractionDigits: 0})}
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-6">
                    Property Value:
                </div>
                <div className="col-lg-6">
                    <div className="input-icon">
                    <input type = 'number' min='100000' max = '2000000' defaultValue = '850000' onChange={props.setPropertyValue} /> 
                        <i>$</i>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-6">
                    Property CG:
                </div>
                <div className="col-lg-6">
                    <div>
                        <div className="input-icon">
                        <input type = 'number' min='-1' max = '1' defaultValue = '0.06' step='0.01' onChange={props.setInvestmentAnnualIncrease} /> 
                        <i>%</i>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="row align-items-center">
                <div className="col-lg-6">
                    Loan Interest Rate:
                </div>
                <div className="col-lg-6">
                    <div className="input-icon">
                    <input type = 'number' min='0' max = '25' defaultValue = '5.6' step = '0.1' onChange={props.setLoanInterestRate} /> 
                        <i>%</i>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="row align-items-center">
                <div className="col-lg-3">
                    HBF:
                </div>
                <div className="col-lg-3">
                    <input type="checkbox" id="25_perc" defaultChecked onChange={props.government_25_change}/>
                </div>
                <div className="col-lg-3">
                    Focus HBF:
                </div>
                <div className="col-lg-3">
                    <input type="checkbox" id="focusHomebuyerFund" defaultChecked onChange={props.setFocusHomebuyerFund}/>
                </div>
            </div>
            <hr/>
            <div className="row align-items-center">
                <div className="col-lg-6">
                    Stamp Duty:
                </div>
                <div className="col-lg-6">
                    {props.investment_projection.stamp_duty.toLocaleString('en-AU', {style: 'currency', currency: 'AUD', maximumFractionDigits: 0, minimumFractionDigits: 0})}
                </div>
            </div>
            <hr/>
            <div className="row align-items-center">
                <div className="col-lg-6">
                    Other Fees:
                </div>
                <div className="col-lg-6">
                {props.investment_projection.other_fees.toLocaleString('en-AU', {style: 'currency', currency: 'AUD', maximumFractionDigits: 0, minimumFractionDigits: 0})}
                </div>
            </div>
            <hr/>
            <div className="row align-items-center">
                <div className="col-lg-6">
                    Deposit %:
                </div>
                <div className="col-lg-6">
                    {Math.round(props.investment_projection.deposit_percentage * 1000)/10}%
                </div>
            </div>
            <hr/>
            <div className="row align-items-center">
                <div className="col-lg-6">
                    Used Budget:
                </div>
                <div className="col-lg-6">
                    Total: {Math.round(props.investment_projection.total_percentage_of_budget_used*100)}% <br/> Housing: {Math.round(props.investment_projection.housing_percentage_of_budget*100)} %
                </div>
            </div>
            <hr/>
            <div className="row align-items-center">
                <div className="col-lg-6">
                    Payment/M:
                </div>
                <div className="col-lg-6">
                {props.investment_projection.monthly_loan_payment.toLocaleString('en-AU', {style: 'currency', currency: 'AUD', maximumFractionDigits: 0, minimumFractionDigits: 0})}
                </div>
            </div>
            <hr/>
            <div className="row align-items-center">
                <div className="col-lg-6">
                    Annual Council Rates (Glen Eira):
                </div>
                <div className="col-lg-6">
                {props.investment_projection.monthly_rates.toLocaleString('en-AU', {style: 'currency', currency: 'AUD', maximumFractionDigits: 0, minimumFractionDigits: 0})}
                </div>
            </div>
      {
      /*
    <div className="w3-cell-row">
        <div className="w3-container w3-cell">
        <p>Loan Interest Rate:</p>
        </div>
        <div className="w3-container w3-cell" style={{width:'50%'}}>
            <div className = "input-icon">
                <input type = 'number' min='0' max = '100' step="0.05" onChange={this.interest_rate_changed} value = {this.state.interest_rate} /><i>%</i>
            </div>
        </div>
    </div>

    <div className="w3-cell-row">
        <div className="w3-container w3-cell">
        <p>Investments Annual Increase:</p>
        </div>
        <div className="w3-container w3-cell" style={{width:'50%'}}>
        <div className = "input-icon">
        <input type="number" min="0" max="100" step="0.5" id="myPercent" value={this.state.share_interest} onChange={this.update_investment_projection} /><i>%</i>
        </div>
        </div>
    </div>

    <div className="w3-cell-row">
        <div className="w3-container w3-cell">
        <p>Percentage allocation towards loan repayment:</p>
        </div>
        <div className="w3-container w3-cell" style={{width:'50%'}}>
            <div className = "input-icon">
        <input type = 'number' min='0' max = '100' step="0.5" onChange={this.extra_payment_towards_loan_changed} value = {this.state.extra_payment_towards_loan} /> <i>%</i>
        </div>
        </div>
    </div>

    <div className="w3-cell-row">
        <div className="w3-container w3-cell">
        <p>Government 25% Eligibility:</p>
        </div>
        <div className="w3-container w3-cell" style={{width:'50%'}}>
        <p>{this.state.government_25 ? "Eligible - Use? " : "Not eligible. "}<input type='checkbox' disabled={!this.state.government_25} checked = {this.state.government_25_using} onChange={this.government_25_using_changed}></input></p>
        </div>
    </div>
    <div className="w3-cell-row">
        <div className="w3-container w3-cell">
        <p>Stamp Duty:</p>
        </div>
        <div className="w3-container w3-cell" style={{width:'50%'}}>
        <p>{(Number(this.state.stamp_duty)).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</p>
        </div>
    </div>
    <div className="w3-cell-row">
        <div className="w3-container w3-cell">
        <p>Other Fees:</p>
        </div>
        <div className="w3-container w3-cell" style={{width:'50%'}}>
        <p>{(Number(this.state.other_fees)).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</p>
        </div>
    </div>
    <div className="w3-cell-row">
        <div className="w3-container w3-cell">
        <p>Deposit %:</p>
        </div>
        <div className="w3-container w3-cell" style={{width:'50%'}}>
        <p>{Math.round(Number(this.state.deposit_percentage)*100)}%</p>
        </div>
    </div>
    <div className="w3-cell-row">
        <div className="w3-container w3-cell">
        <p>Used Budget:</p>
        </div>
        <div className="w3-container w3-cell" style={{width:'50%'}}>
        <p>Total: {100-Math.round(Number(this.state.extra_room_in_budget_after_loan)*100)}% | Housing: {Math.round(Number(this.state.housing_percentage_of_budget)*100)}%</p>
        </div>
    </div>                        
    <div className="w3-cell-row">
        <div className="w3-container w3-cell">
        <p>Monthly Loan Payment:</p>
        </div>
        <div className="w3-container w3-cell" style={{width:'50%'}}>
        <p>{(Number(this.state.annual_loan_payment)).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</p>
        </div>
    </div>

    <div className="w3-cell-row">
        <div className="w3-container w3-cell">
        <p>Rates (Glen Eira) Monthly:</p>
        </div>
        <div className="w3-container w3-cell" style={{width:'50%'}}>
        <p>{(Number(this.state.rates/12)).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</p>
        </div>
    </div>

    */}

    </div> 
    );
  
}

export default PropertyProjectionInputs;