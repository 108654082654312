
import * as React from 'react';
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react';
import {useState} from 'react';
import AddInvestmentRow from './AddInvestmentRow';
import {Transaction, TransactionPeriod, CategorisedTransaction} from '../types';


const AddInvestmentTable = (props: { 
      data: any,
      setDate: any,
      setAUDUSD: any
      setSamuelValue: any,
      setMelValue: any,
      setSamuelInvested: any,
      setSamuelWithdrawn: any,
      setMelInvested: any,
      setMelWithdrawn: any,
      submit: any
    }) =>  {
  
    const rows: JSX.Element[] = [];

    const [adding, setAdding] = useState(false);


    
    props.data.forEach((r:any) => {

      rows.push(
        <AddInvestmentRow
          key={r.date+r.samuel_value+r.mel_value}
          data={r}
          showAllColumns={adding}
        />
      );
    })

    const the_date = new Date();
    let months = "";
    let month = (the_date.getMonth() + 1);
    if (month < 10)
      months = "0" + month;
    else
      months = "" + month;
    const default_date = the_date.getFullYear() + "-" + months + "-" + the_date.getDate();
    function submit()
    {
      props.submit();

    }

    function revealColumns()
    {
      setAdding(true);
    }


    React.useEffect(() => {
      props.setDate(default_date);
      props.setSamuelInvested(props.data[0].samuel_invested);
      props.setSamuelWithdrawn(props.data[0].samuel_withdrawn);
      props.setMelInvested(props.data[0].mel_invested);
      props.setMelWithdrawn(props.data[0].mel_withdrawn);
    }, []);

    return (
      <table className="table table-striped table-bordered table-hover ">
        <thead>
          <tr>
            <th>Date</th>
            <th>AUD USD</th>
            <th>Samuel Value</th>
            <th>Mel Value</th>
            {(adding === true) &&
            <th>Samuel Invested</th>
            }
            {(adding === true) &&
            <th>Samuel Withdrawn</th> 
            }        
            {(adding === true) &&   
            <th>Mel Invested</th>
            }
            {(adding === true) &&  
            <th>Mel Withdrawn</th>
            }
            {(adding === true) &&
            <th></th>
            }
            
          </tr>
        </thead>
      <tbody>
        
        <tr key={default_date}>
          <td><input size={10} defaultValue={default_date} onChange={(e) => {props.setDate(e.target.value)}} onClick={revealColumns}></input></td>
          <td><input size={10} defaultValue={0} onChange={(e) => {props.setAUDUSD(e.target.value)}} onClick={revealColumns}></input></td>
          <td><input size={10} defaultValue={0} onChange={(e) => {props.setSamuelValue(e.target.value)}} onClick={revealColumns}></input></td>
          <td><input size={10} defaultValue={0} onChange={(e) => {props.setMelValue(e.target.value)}} onClick={revealColumns}></input></td>
          {(adding === true) &&
          <td><input size={10} defaultValue={props.data[0].samuel_invested} onChange={(e) => {props.setSamuelInvested(e.target.value)}}></input></td>
          }
          {(adding === true) &&
          <td><input size={10} defaultValue={props.data[0].samuel_withdrawn} onChange={(e) => {props.setSamuelWithdrawn(e.target.value)}}></input></td>
          }
          {(adding === true) &&
          <td><input size={10} defaultValue={props.data[0].mel_invested} onChange={(e) => {props.setMelInvested(e.target.value)}}></input></td>
          }
          {(adding === true) &&
          <td><input size={10} defaultValue={props.data[0].mel_withdrawn} onChange={(e) => {props.setMelWithdrawn(e.target.value)}}></input></td>
          }
          {(adding === true) &&
          <td><button onClick={(e) => {submit()}}>Add</button></td>
          }
        </tr>
        
          {rows}
    </tbody>
    </table>
    );
  
}

export default AddInvestmentTable;