import * as React from 'react';

import {Transaction} from '../types';
import {useState} from 'react';

const BudgetRowTransactions = (props: {transactions: Transaction[]; highlight: boolean}) => {

    //var sortBy = "Date";
    const [sortBy, setSortBy] = useState("Date");

    const sortByAmount = (event: any) => {
        setSortBy("Amount");
      };
    const sortByDate = (event: any) => {
        setSortBy("Date");
      };

    const budgetRowTransactionData = props.transactions;

    const rows: JSX.Element[] = [];



    if (budgetRowTransactionData != undefined)
    {      
        if (sortBy === "Date")
            budgetRowTransactionData.sort((a, b)=>{
                if (a.date > b.date)
                    return -1;
                else if (a.date < b.date)
                    return 1;
                else
                    return 0;
            })

        else if (sortBy === "Amount")
            budgetRowTransactionData.sort((a, b)=>{
                if (a.debit - a.credit < b.debit - b.credit)
                    return 1;
                else if (a.debit - a.credit > b.debit - b.credit)
                    return -1;
                else
                    return 0;
        })

      budgetRowTransactionData.forEach((transaction) => {
        rows.push(
            <tr key = {transaction.id} className="">
              <td>{transaction.date}</td>  
              <td>{transaction.details}</td>  
              <td>{transaction.debit.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>  
              <td>{transaction.credit.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>  
              <td>{transaction.account}</td>  
            </tr>
        );
      })
    }

    let classes = "table small table-sm sortTable ";
    if (props.highlight)
      classes += "border border-success border-5 ";

    return (
        <table className= {classes}>
            <thead>
            <tr className="">
                <th onClick={sortByDate} className = "">Date</th>
                <th>Details</th>
                <th onClick={sortByAmount}>Debit</th>
                <th onClick={sortByAmount}>Credit</th>
                <th>Account</th>
            </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
  
}

export default BudgetRowTransactions;