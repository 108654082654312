import * as React from 'react';
import {Transaction, CategorisedTransaction} from '../types';

const AddInvestmentRow = (props: { data: any, showAllColumns: boolean}) => {



    return (
      <tr key={props.data.date}>
        <td>{props.data.date}</td>
        <td>{props.data.audusd}</td>
       
        <td className = "money">{Number(props.data.samuel_value).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
        <td className = "money">{Number(props.data.mel_value).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
        
        { (props.showAllColumns) &&
        <td className = "money">{Number(props.data.samuel_invested).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
        }
        { (props.showAllColumns) &&
        <td className = "money">{Number(props.data.samuel_withdrawn).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
        }
        { (props.showAllColumns) &&
        <td className = "money">{Number(props.data.mel_invested).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
        }
        { (props.showAllColumns) &&
        <td className = "money">{Number(props.data.mel_withdrawn).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
        }
        { (props.showAllColumns) &&
        <td></td>
        }
      </tr>
    );
  
}

export default AddInvestmentRow;