import * as React from 'react';

import {Transaction} from '../types';
import {useState} from 'react';
import {Person, AccountBalance} from '../types';

function calculateMonthsToRepay(monthlyRepayment: number, startingLoanAmount: number, interestRate: number, offsetAccountAmount: number):number {
    // Calculate effective loan amount after considering the offset account
    const effectiveLoanAmount = startingLoanAmount - offsetAccountAmount;



    // Calculate remaining loan balance after each repayment
    let remainingLoanBalance = effectiveLoanAmount;

    // Calculate months to repay
    let monthsToRepay = 0;
    while (remainingLoanBalance > 0) {
            // Calculate monthly interest
        let monthlyInterest = remainingLoanBalance * (interestRate / 12);

        remainingLoanBalance -= (monthlyRepayment - monthlyInterest);
       
        monthsToRepay++;
    }

    return monthsToRepay - (-remainingLoanBalance/monthlyRepayment);
}

const BudgetInfoDisplay = (props: { budgetRowData: any; accounts: AccountBalance[];
    pocket_money: {"Samuel Pocket Money":Transaction[], "Mel Pocket Money": Transaction[], "Cat": Transaction[]}, people: Person[]}) => {

    //var sortBy = "Date";
    const [sortBy, setSortBy] = useState("Date");

    const sortByAmount = (event: any) => {
        setSortBy("Amount");
      };
    const sortByDate = (event: any) => {
        setSortBy("Date");
      };


    const rows: JSX.Element[] = [];
    const accountRows: JSX.Element[] = [];

   let samuel_pocket_money_balance = 0;
   let mel_pocket_money_balance = 0;
   let cat_balance = 0;
    

   props.pocket_money['Samuel Pocket Money'].forEach((t)=>{
    if (t.details === "PocketMoneyAllowanceSamuel" || t.details === "To Pocket Money Samuel - Pocketmoneysamuel")
        samuel_pocket_money_balance += 0;
    else
        samuel_pocket_money_balance = samuel_pocket_money_balance + Number(t.credit) - Number(t.debit);
   });

   props.pocket_money['Mel Pocket Money'].forEach((t)=>{
    if (t.details === "PocketMoneyAllowanceMel" || t.details === "To Pocket Money Mel - Pocketmoneymel")
        mel_pocket_money_balance += 0;
    else
        mel_pocket_money_balance += Number(t.credit - t.debit);
   });

   props.pocket_money['Cat'].forEach((t)=>{
    if (t.details === "AllowanceCat")
        cat_balance += 0;
    else
        cat_balance += Number(t.credit - t.debit);
   });

   let cat_start_date = new Date(2022, 11, 1)
   let now_date = new Date();
   let time_passed = monthDiff(cat_start_date, now_date);

   // 145.83 = monhtly budget for cat
   cat_balance += (time_passed + 1) * 145.83;

   function monthDiff(d1: Date, d2: Date) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }


    rows.push(
        <tr key="PocketMoneySamuel">
            <td>Pocket Money Samuel</td>
            <td className = "money">{samuel_pocket_money_balance.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
        </tr>
    );
    rows.push(
        <tr key="PocketMoneyMel">
            <td>Pocket Money Mel</td>
            <td className = "money">{mel_pocket_money_balance.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
        </tr>
    );
    rows.push(
        <tr key="Cat">
            <td>Cat</td>
            <td className = "money">{cat_balance.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
        </tr>
    );

    for (var act in props.accounts)
    {
        var n = props.accounts[act].name.replace(/_/g, " ").toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');

        if (Math.round(props.accounts[act].value*100) != 0)
            accountRows.push(
                <tr key={props.accounts[act].name as string}>
                    <td>{n}</td>
                    <td className = "money">{props.accounts[act].value.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
                </tr>
            );

    }
      
    


    //let etoro = (Number(props.people[0].value) + Number(props.people[1].value));
    let total = 0;
    let total_cash = 0;

    for (var account of props.accounts)
    {
        if (account.name != "home_loan")
            total_cash += account.value;
        total += account.value;
    }
   

    return (
        <div>
            <h3>Budget Information</h3>
            <table className= {"table small table-sm sortTable"}>
                <thead>
                <tr className="">
                    <th className = "">Person (Virtual Accounts)</th>
                    <th>Pocket Money Debt</th>
                </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>

            <table className= {"table small table-sm sortTable"}>
                <thead>
                <tr className="">
                    <th className = "">Account (Real)</th>
                    <th>Balance</th>
                </tr>
                </thead>
                <tbody>
                    {accountRows}
                    <tr>
                        <td><b>Net Cash</b></td>
                        <td className = "money"><b>{total_cash.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</b></td>
                    </tr>
                    <tr>
                        <td><b>Net Position</b></td>
                        <td className = "money"><b>{total.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</b></td>
                    </tr>
                </tbody>
            </table>
            <table className= {"table small table-sm sortTable"}>
                <thead>
                <tr className="">
                    <th className = "">Home Loan (Hardcoded)</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Repayment Min</td>
                        <td className = "money">{(3426.75).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
                    </tr>
                    <tr>
                        <td>Repayment Time Min</td>
                        <td className = "money">{(calculateMonthsToRepay(3426.75, 568287, 0.0599, 20000)/12).toLocaleString('en-AU')} years</td>
                    </tr>
                    <tr>
                        <td>Repayment+</td>
                        <td className = "money">{(3426.75 + 2342).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
                    </tr>
                    <tr>
                        <td>Repayment Time+</td>
                        <td className = "money">{(calculateMonthsToRepay(3426.75+2342, 568287, 0.0599, 20000)/12).toLocaleString('en-AU')} years</td>
                    </tr>
                    <tr>
                        <td>$3000 Dollar Time Value</td>
                        <td className = "money">{((calculateMonthsToRepay(3426.75+2342, 568287, 0.0599, 20000) - calculateMonthsToRepay(3426.75+2342, 568287, 0.0599, 20000+3000))/12*365).toLocaleString('en-AU')} days</td>
                    </tr>
                    <tr>
                        <td>$100 Dollar Time Value</td>
                        <td className = "money">{((calculateMonthsToRepay(3426.75+2342, 568287, 0.0599, 20000) - calculateMonthsToRepay(3426.75+2342, 568287, 0.0599, 20000+100))/12*365).toLocaleString('en-AU')} days</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
  
}

export default BudgetInfoDisplay;