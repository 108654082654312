import BudgetTable from './budget_table';
import * as React from 'react';
import {useState} from 'react';
import {Transaction, TransactionPeriod, CategorisedTransaction as CategorisedTransaction, Data, Period, HelpDebt, BudgetWithTransactions} from '../types';
import { convertGrossToNet } from '../TaxFunctions';
import BudgetRowTransactions from './budget_row_transactions';
import BudgetInfoDisplay from './BudgetInfoDisplay';
import {Person, InvestmentProjection, AccountBalance, Budget} from '../types';
import { propTypes } from 'react-bootstrap/esm/Image';

const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];




function GetPocketMoney(transactions_master: TransactionPeriod[]) : 
{"Samuel Pocket Money" : Transaction[], "Mel Pocket Money": Transaction[], "Cat": Transaction[]}
{

  let spm : Transaction[] = [];
  let mpm: Transaction[] = [];
  let cpm: Transaction[] = [];

  transactions_master.forEach((transactionPeriod)=>{
      transactionPeriod.trans.forEach((transaction) => {
        if (transaction.category === "Pocket Money Samuel")
          spm.push({
          id: transaction.id,
          date: transaction.date,
          category: transaction.category,
          credit: transaction.credit,
          debit: transaction.debit,
          details: transaction.details,
          account: transaction.account
        });

      if (transaction.category === "Pocket Money Mel")
        mpm.push({
        id: transaction.id,
        date: transaction.date,
        category: transaction.category,
        credit: transaction.credit,
        debit: transaction.debit,
        details: transaction.details,
        account: transaction.account
      });

    if (transaction.category === "Cat")
      cpm.push({
      id: transaction.id,
      date: transaction.date,
      category: transaction.category,
      credit: transaction.credit,
      debit: transaction.debit,
      details: transaction.details,
      account: transaction.account
    });
      });
  });

  //console.log({"Samuel Pocket Money" : spm, "Mel Pocket Money": mpm, "Cat": cpm});
  return {"Samuel Pocket Money" : spm, "Mel Pocket Money": mpm, "Cat": cpm};
}


function BudgetApp(props: {handleClick: any, handlePeriodChange: any, pickablePeriods: any, selectedBudget:number, budget_with_transactions: BudgetWithTransactions, transactions_master: TransactionPeriod[], 
    help_debt: HelpDebt[], help_tax_brackets: any[], income_tax_brackets: any[], account_balances: AccountBalance[], people: Person[],
  
    budgetRowData: CategorisedTransaction[],
    freq: string,
    period: string,
    budgetChanged: any

  }) {

      var [hoveredRow, setHoveredRow] = useState<undefined | Transaction[]>(undefined);
      var [selectedRow, setSelectedRow] = useState<undefined | CategorisedTransaction>(undefined);
      var [pocketMoney, setPocketMoney] = useState(GetPocketMoney(props.transactions_master));

      //let budgetRowData = TimePeriods(freq, period);
      

      const handleClick = (event: any) =>{

        props.handleClick(event);
        
        if (selectedRow !== undefined)
        setSelectedRow(props.budgetRowData.find(row => {
          return row.category === selectedRow!.category;
        }));
      };

      const handlePeriodChange = (event: any) =>{
        props.handlePeriodChange(event);
      };

      const rowHover = (event: any, category: string) =>{

        let hoveredData = props.budgetRowData.find(row => {
          return row.category === category;
        })?.transactions;

        setHoveredRow(hoveredData);
      };

      const rowUnHover = (event: any) => {
        setHoveredRow(undefined);
      };

      const rowClick = (event: any, category: string) => {
        let selectedData = props.budgetRowData.find(row => {
          return row.category === category;
        });

        if (selectedRow?.category === category)
          setSelectedRow(undefined);
        else
          setSelectedRow(selectedData);
      };
 

    return (
      <div className="App">
        <div className="container-fluid">
          <div className="row">
          <div className="col-lg-6">
            <div className = "display-buttons">
              <button onClick={handleClick} value = "Weekly">Weekly</button>
              <button onClick={handleClick} value = "Fortnightly">Fortnightly</button>
              <button onClick={handleClick} value = "Monthly">Monthly</button>
              <button onClick={handleClick} value = "Annually">Annually</button>
            </div>
            <BudgetTable 
              periodValue={props.period} 
              budgetRowData={props.budgetRowData} 
              freq={props.freq} 
              budgetPeriods={props.pickablePeriods} 
              periodSelected={handlePeriodChange} 
              rowHover={rowHover} 
              rowUnHover={rowUnHover} 
              rowClick={rowClick} 
              selectedRow={selectedRow?.category!}
              budgetNumber={props.selectedBudget}
              budgetChanged={props.budgetChanged}
            />
          </div>

          <div className="col-lg-6">

            {(selectedRow !== undefined || hoveredRow !== undefined) &&
            <BudgetRowTransactions transactions={selectedRow !== undefined ? selectedRow.transactions : hoveredRow!} highlight={selectedRow !== undefined ? true : false!}/>
            }
            {(selectedRow === undefined && hoveredRow === undefined) &&
            <BudgetInfoDisplay budgetRowData={props.budgetRowData} 
            accounts = {props.account_balances} pocket_money={pocketMoney} people={props.people}></BudgetInfoDisplay>
            }
            </div>

          </div>
        </div>
      </div>
    )

  
}

export default BudgetApp;
