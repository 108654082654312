import * as React from 'react';
import {Transaction, CategorisedTransaction} from '../types';

const InvestmentProjectionRow = (props: any) => {

    const budgetData = props.budgetData;

    let total = 0;
    //budgetData.transactions.forEach((t) =>{
     // total += t.credit - t.debit;
    //});


    return (
      <tr>
        <td>{props.year}</td>
        <td className = "money">{Number(props.value).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
        <td className = "money">{Number(props.investment_increase).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
        <td className = "money">{Number(props.net_total).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>

      </tr>
    );
  
}

export default InvestmentProjectionRow;