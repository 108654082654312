import './App.css';
import * as React from 'react';
import * as Bootstrap from 'react-bootstrap'

function MenuItem(props:{name: string, active: boolean, onClick: any}) {



if (props.active)
  return (
    <Bootstrap.Nav.Link key={props.name} onMouseDown={((event) => props.onClick(event, props.name))} href="#"><strong>{props.name}</strong></Bootstrap.Nav.Link>

     
  )
  else 
  return (
    <Bootstrap.Nav.Link key={props.name} onMouseDown={((event) => props.onClick(event, props.name))} href="#">{props.name}</Bootstrap.Nav.Link>
  );

}

export default MenuItem;
