//import AddInvestmentTable from './AddInvestmentTable';
import * as React from 'react';
import {useState} from 'react';
import {Transaction, TransactionPeriod, CategorisedTransaction as CategorisedTransaction, CSVLoadedTransaction} from '../types';
import UploadTransactionsTable from './UploadTransactionsTable';
import UploadTransactionsFileTable from './UploadTransactionsFileTable';
import Papa from 'papaparse';
import {format} from 'date-fns';



function UploadTransactionsApp(props: {username: string, password: string, reload: any, unprocessed_transactions: any, budget_categories: any|string[]}) {

  //const unprocessed_transactions: Transaction[] = [];
  //const budget_categories: string[] = [];


  const accounts : string[] = ["qantas_credit_card", "macquarie_offset", "home_loan"];

  const [loadedData, setLoadedData] = useState<CSVLoadedTransaction[]>();
  const [noRecentTrans, setNoRecentTrans] = useState<number>(10);


  let tt: Transaction[] = [];
  const [category_transactions, setCategoryTransactions] = useState<any>({account:"None", transactions: tt});


  let account_transactions : {account:string, transactions: Transaction[]}[] = [];

    for (var i = 0 ; i<accounts.length; i++)
    {
      let trans: Transaction[] = [];
      for (var j = 0; j<props.unprocessed_transactions.length; j++)
      {
        if (props.unprocessed_transactions[j].account === accounts[i])
          trans.push(props.unprocessed_transactions[j]);

        if ( trans.length >= 250)
          break;
      }

      //trans.reverse();
  
      let aa : {account:string, transactions: Transaction[]}= {account:accounts[i], transactions: trans};
      account_transactions.push(aa);



      
    }

    React.useEffect(()=>{
      if (account_transactions.length >= i)
      setCategoryTransactions(account_transactions[0]);
    },[])

  const [accountSelected, setAccountSelected] = useState("0");

  function categoryChanged(e:any)
  {

    setCategoryTransactions(undefined);

    if (account_transactions.length >= i)
    {
      setCategoryTransactions(account_transactions[e.target.value]);
      setAccountSelected(e.target.value);
    }
      
  }

  const options: JSX.Element[] = [];


  for (var i = 0; i < accounts.length; i++)
  {
    
    options.push(
      <option key={accounts[i]} value={i}>{accounts[i]}</option>
    );
  }

  const [selectedFileName, setSelectedFileName] = useState("Not Set");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [succesfullyAdded, setSuccesfullyAdded] = useState(-2);

  const fileUpload = (event:any) => 
    {
      if (event.target.files[0] != undefined)
      {
        setSelectedFileName(event.target.files[0].name);

        setIsFilePicked(true);

        Papa.parse(event.target.files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            if (accountSelected === "0")
              submit_qantas_credit_card(results.data, event.target.files[0].name)
            else if (accountSelected === "1")
              submit_macquarie_offset(results.data, event.target.files[0].name)
          },
        });
      }
      else
      {
        setIsFilePicked(false);
        setSelectedFileName("Not Set");
      }



  }

  function submit_woolworths_credit_card(fileContents: any, fileName: string)
  {
    let data: {filename: string, date: string, details: string, debit: number, credit: number, description: string, account: string}[] = [];
    for (var i = 0; i < fileContents.length; i++)
    {

      let d = new Date(fileContents[i]["Transaction Date"]);
      let dstring = format(d, "yyyy-MM-dd")

      //alert(fileContents[i]["Details"].replace("/'/g", ""));
      let dataObject : {filename: string, date: string, details: string, debit: number, credit: number, description: string, account: string} = {
        filename: fileName,
        date: dstring,
        details: fileContents[i]["Details"].replace(/'/g, ""),
        debit: fileContents[i]["Debit"],
        credit: fileContents[i]["Credit"],
        description: fileContents[i]["Original Description"].replace(/'/g, ""),
        account: "woolworths_credit_card",
      };


      data.push(dataObject);

    }
    //setLoadedData(data);
  }

  function submit_macquarie_offset(fileContents: any, fileName: string)
  {
    let csvData: CSVLoadedTransaction[] = [];
    for (var i = 0; i < fileContents.length; i++)
    {

      let d = new Date(fileContents[i]["Transaction Date"]);
      let dstring = format(d, "yyyy-MM-dd")

      let transObject : CSVLoadedTransaction =
      {
        date: dstring, 
        credit: fileContents[i]["Credit"], 
        debit: fileContents[i]["Debit"],
        details: fileContents[i]["Details"].replace(/'/g, ""),
        account: "macquarie_offset",
        duplicate: false,
        filename: fileName,
        id: i
      }


      csvData.push(transObject);

    }
    fileUploadCommonSteps(csvData);
  }

  function submit_qantas_credit_card(fileContents: any, fileName: string)
  {
    let csvData: CSVLoadedTransaction[] = [];
    for (var i = 0; i < fileContents.length; i++)
    {

      let d = new Date(fileContents[i]["Date"]);
      let dstring = format(d, "yyyy-MM-dd")
      var deb = 0;
      var cred = 0;

      if (fileContents[i]["Amount"] < 0)
        deb = Math.abs(fileContents[i]["Amount"]);
      else 
        cred = Math.abs(fileContents[i]["Amount"]);

      let transObject : CSVLoadedTransaction = {
        date: dstring,
        credit: cred,
        debit: deb,
        details: fileContents[i]["Description"].replace(/'/g, ""),
        account: "qantas_credit_card",
        duplicate: false,
        filename: fileName,
        id: i
      };

      

      csvData.push(transObject);

    }



  fileUploadCommonSteps(csvData);
    
  }

  function fileUploadCommonSteps(toLoad: CSVLoadedTransaction[])
  {
    let found : boolean = false;
    category_transactions.transactions.forEach((c:Transaction) => {
      toLoad.forEach((r:CSVLoadedTransaction) => {
      if (r.account === c.account && r.date === c.date && Math.round(r.credit * 100) === Math.round(c.credit * 100) && Math.round(r.debit * 100) === Math.round(c.debit * 100) && r.details === c.details)
      {
        found = true;
        r.duplicate = true;
      }
    });
  });

    setLoadedData(toLoad);
  }

  function addCSVDataToDatabase()
  {
    addDataToDatabase(loadedData!);
  }

  function addDataToDatabase(data: CSVLoadedTransaction[])
  {
    let afterRemoval : CSVLoadedTransaction[] = [];
    // Remove duplicates based on t.duplicate = true, set in UploadTransactionsFileTable.tsx
    data.forEach(t => {
      if (!t.duplicate)
      afterRemoval.push(t);
    });

    console.log("Non duplicates added: ");
    console.log(afterRemoval);
    
    setSuccesfullyAdded(0);
    const formData:any = new FormData();
    formData.append('username', props.username);
    formData.append('password', props.password);

    formData.append('loadedData', JSON.stringify(afterRemoval));

  
    fetch("https://app.sholmes.org/api_post_transactions.php", {
      method: "POST",
      redirect: 'follow',
      body: new URLSearchParams(formData)
    })
    .then(function(respo: any) {
      if (respo.ok) {
        setSuccesfullyAdded(1);
      } else (setSuccesfullyAdded(-1));

      return respo;
    })
    .then(resp => resp.json())
    .then(response => AfterSave(response))

  }

  function AfterSave(response: any)
  {
    console.log(response);
    props.reload(props.username, props.password);
  }

  const [manualDate, setManualDate] = useState("");
  const [manualDebit, setManualDebit] = useState(0);
  const [manualCredit, setManualCredit] = useState(0);
  const [manualDetails, setManualDetails] = useState("");

  function submitManual()
  {
    let data: CSVLoadedTransaction[] = [];


      //let d = new Date(manualDate);
      //let dstring = format(d, "yyyy-MM-dd")
      let dstring = manualDate;

      let dataObject : CSVLoadedTransaction = {
        filename: "manual",
        date: dstring,
        details: manualDetails,
        debit: manualDebit,
        credit: manualCredit,
        account: "home_loan",
        duplicate: false,
        id: 0
      };

      if (manualDate.length < 6 || (manualDebit == 0 && manualCredit == 0) || manualDetails.length < 2)
      {
        alert(manualDate.length);
        return;
      }
        

      data.push(dataObject);


    
      addDataToDatabase(data);
  }


  function recentTransChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    setNoRecentTrans(Number(event.target.value));
  }

    return (
      <div className="AddInvestmentApp">
        <div className="container-fluid">
        <div className="row">
          <div className="col col-lg-12">  
            <h3>Account:</h3>
            <select name="accounts" id="accounts" onChange={((event) => categoryChanged(event))}>
              {options}
            </select>
            <br/><br/>
            <div># recent transactions to display:</div>
            <input name="noRecentTrans" id ="noRecentTrans" onChange={((event) => recentTransChanged(event))}/>
            <br/><br/>
          </div>
        </div>
          <div className="row">
            <div className="col col-lg-12">  
              <UploadTransactionsTable transactions={category_transactions} noTransDisplayed={noRecentTrans}></UploadTransactionsTable>
            </div>
          </div>
          

              { (accountSelected === "2" ) &&
                <div className="row"> 
                  <div  className="col col-lg-2">
                    Date: <input type="date" name="date" onChange={((event)=> {setManualDate(event.target.value)})}/>
                  </div>
                  <div  className="col col-lg-2">
                    Details: <input type="text" name="details" onChange={((event)=> {setManualDetails(event.target.value)})} />
                  </div>
                  <div  className="col col-lg-2">
                    Debit: <input type="number" name="debit" onChange={((event)=> {setManualDebit(Number(event.target.value))})} />
                  </div>
                  <div  className="col col-lg-2">
                    Credit: <input type="number" name="credit" onChange={((event)=> {setManualCredit(Number(event.target.value))})} />
                  </div>
                  <div  className="col col-lg-1">
                    {succesfullyAdded === 1 && 
                    <button name="submit" onClick={submitManual} className = "bg-success" >Add</button>
                    }
                    {succesfullyAdded === -1 && 
                    <button name="submit" onClick={submitManual} className = "bg-danger" >Add</button>
                    }
                    {succesfullyAdded === 0 && 
                    <button name="submit" onClick={submitManual} className = "bg-warning" >Add</button>
                    }
                    {succesfullyAdded === -2 && 
                    <button name="submit" onClick={submitManual} >Add</button>
                    }
                  </div>
                </div>
              }

              {(accountSelected === "0" || accountSelected === "1") &&
                <div className="row"> 
                  <div className="col col-lg-12">
                    <input type="file" name="file" accept=".csv" onChange={fileUpload} />
                  </div>
                </div>
              }

              { isFilePicked && (accountSelected === "0" || accountSelected === "1") && selectedFileName!= undefined &&
                <div className="row"> 
                  <div className="col col-lg-12">
                    <UploadTransactionsFileTable filename={selectedFileName} uploadedTransactions={loadedData!} comparisonTransactions={category_transactions.transactions} setuploadedTransactionsState={setLoadedData}></UploadTransactionsFileTable>
                    {succesfullyAdded === 1 && 
                    <button onClick={addCSVDataToDatabase} className = "bg-success" >Upload</button>
                    }
                    {succesfullyAdded === -1 && 
                    <button onClick={addCSVDataToDatabase} className = "bg-danger" >Upload</button>
                    }
                    {succesfullyAdded === 0 && 
                    <button onClick={addCSVDataToDatabase} className = "bg-warning" >Upload</button>
                    }
                    {succesfullyAdded === -2 && 
                    <button onClick={addCSVDataToDatabase} >Upload</button>
                    }

                  </div>
                </div>
              }

        </div>
      </div>
    )

  
}

export default UploadTransactionsApp;
