
import * as React from 'react';
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react';
import {useState} from 'react';
import {Transaction, TransactionPeriod, CategorisedTransaction, Person, PropertyProjectionN, PropertyProjection} from '../types';
import PropertyProjectionRow from './PropertyProjectionRow';


const PropertyProjectionTable = (props: { 
      rowData: PropertyProjection;
      propertyValue: number; 
    }) =>  {
  
    const rows: JSX.Element[] = [];
    const rowData = props.rowData;

    let totalBudgeted = 0;
    let totalAmount = 0;

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();

    for (var i = 0; i < rowData.projection.length; i++)
    {
      

      let period_name = "";
      if (month < 6)
        period_name = (year-1)  + " - " +  (year);
      else
        period_name = (year)  + " - " +  (year+1);

      if (i === 0)
        period_name = "Before Purchase";
      if (i === 1)
        period_name = "After Purchase";

        //rowData[i][0].value
        rows.push(
          <PropertyProjectionRow
          key = {period_name}
          year = {period_name}
          property_value = {rowData.projection[i].property_value}
          loan_value = {rowData.projection[i].loan_value}
          net_salary = {Number(rowData.budget)}
          budget = {Number(rowData.budget)}
          yearly_payment = {Number(rowData.projection[i].yearly_payment)}
          income = {rowData.income}
          spending = {Number(rowData.budget)}
          compulsory_payment = {Number(rowData.projection[i].compulsoryPayment)}
          offset = {rowData.projection[i].offset}
          ownedShare = {rowData.projection[i].ownedShare}
          />
        );
      
        year ++;
    }
    


    return (
      <table className="table table-striped table-bordered table-hover ">
        <thead>
          <tr>
            <th style={{width: "10%"}}>Year</th>
            <th style={{width: "10%"}}>Property Value</th>
            <th style={{width: "10%"}}>Loan Value</th>
            <th style={{width: "10%"}}>Net Salary</th>
            <th style={{width: "10%"}}>After Expenses</th>
            <th style={{width: "10%"}}>Compulsory Payment</th>
            <th style={{width: "10%"}}>Extra Loan Payment</th>
            <th style={{width: "10%"}}>Offset</th>
            <th style={{width: "10%"}}>Owned Share</th>
          </tr>
        </thead>
      <tbody>
          {rows}
    </tbody>
    </table>
    );
  
}

export default PropertyProjectionTable;