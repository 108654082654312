
import * as React from 'react';
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react';
import {useState, useEffect} from 'react';
//import AddInvestmentRow from './AddInvestmentRow';
import {Transaction, TransactionPeriod, CategorisedTransaction, CSVLoadedTransaction} from '../types';
import {format} from 'date-fns';

const UploadTransactionsTable = (props: { 
    uploadedTransactions: CSVLoadedTransaction[],
    filename: string,
    comparisonTransactions: Transaction[],
    setuploadedTransactionsState : any
    }) =>  {
  
    const rows: JSX.Element[] = [];

    var oldestDate: Date = new Date();
    var oldestDateString: string = format(oldestDate, "yyyy-MM-dd");

    useEffect(() => {
    }, [props.uploadedTransactions]);
    
    if (props.uploadedTransactions != undefined)
    {

      
      props.uploadedTransactions.forEach((r:CSVLoadedTransaction) => {
        let d = new Date(r.date);
        
        if (oldestDate > d)
        {
          oldestDate = d;
          oldestDateString = format(oldestDate, "yyyy-MM-dd");
        }
          
      });

      let searchTransactions : Transaction[] = [];

      props.comparisonTransactions.forEach((r:Transaction) => {
       if ((new Date(r.date)) < oldestDate)
       {}
       else
       {
        searchTransactions.push(r);
       }
          
      });

      props.uploadedTransactions.forEach((r:CSVLoadedTransaction) => {



        if (r.duplicate)
          rows.push(
            <tr className = "bg-warning" onClick={() => toggleDuplicate(r.id, false)}>
              <td>{r.date}</td>
              <td>{r.details}</td>
              <td>{r.debit}</td>
              <td>{r.credit}</td>  
            </tr>
          )
          else
          rows.push(
            <tr className = "bg-success" onClick={() => toggleDuplicate(r.id, true)}>
              <td>{r.date}</td>
              <td>{r.details}</td>
              <td>{r.debit}</td>
              <td>{r.credit}</td>  
            </tr>
          );
      });
    }


    function toggleDuplicate(idSwap: number, duplicate: boolean)
    {
      let tempState : CSVLoadedTransaction[] = props.uploadedTransactions.slice();

      tempState.forEach(r => {
        if (r.id === idSwap)
        {
          r.duplicate = duplicate;
          console.log(r.duplicate);
          console.log(r.id);
          props.setuploadedTransactionsState(tempState);
          
          return;
        }

      });
    }

    return (
      <div>
        <h3>{props.filename}</h3>
      <table className="table table-striped table-bordered table-hover ">
        <thead>
          <tr>
            <th>Date</th>
            <th>Details</th>
            <th>Debit</th>
            <th>Credit</th>
          </tr>
        </thead>
      <tbody>        
          {rows}
    </tbody>
    </table>
    </div>
    );
  
}

export default UploadTransactionsTable;