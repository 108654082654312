
import * as React from 'react';
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react';
import {useState} from 'react';
import {Transaction, TransactionPeriod, CategorisedTransaction, Person, InvestmentProjection2} from '../types';
import InvestmentProjectionRow from './InvestmentProjectionRow';


const InvestmentProjectionTable = (props: { 
      investmentProjection: InvestmentProjection2[]; 
    }) =>  {
  
    const rows: JSX.Element[] = [];
    const investmentProjection = props.investmentProjection;

    let totalBudgeted = 0;
    let totalAmount = 0;

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();

    for (var i = 0; i < investmentProjection.length; i++)
    {
      

      let period_name = "";
      if (month < 6)
        period_name = (year-1)  + " - " +  (year)
      else
        period_name = (year)  + " - " +  (year+1)

      if (i === 0)
        period_name = "End of last FY";
      else if (i === 1)
        period_name = "Current";

      let investment_increase = 0;
      

      if (i === 0)
      {
        //investment_increase = investmentProjection[0]. .investment_growth_net;//investmentProjection[i].people[0].investment_gain + investmentProjection[i].people[1].investment_gain;
      }
      else if (i === 1)
      {
        //investment_increase = investmentProjection[i].people[0].investment_gain + investmentProjection[i].people[1].investment_gain; //- rowData[i-1].people[0].investment_gain;
      }
      else
      {
        //investment_increase = investmentProjection[i].investment_growth_net
      }

        //rowData[i][0].value
        rows.push(
          <InvestmentProjectionRow
          key = {period_name}
          year = {period_name}
          value = {Number(investmentProjection[i].value)}
          net_salary = {Number(investmentProjection[i].salaryOnlyIncome)}
          spending = {Number(investmentProjection[i].expenses)}
          investment_increase = {Number(investmentProjection[i].valueIncreaseFromInvestments)}
          net_total = {Number(investmentProjection[i].income + investmentProjection[i].expenses)}
          />
        );
      
        year ++;
    }
    


    return (
      <table className="table table-striped table-bordered table-hover ">
        <thead>
          <tr>
            <th>Year</th>
            <th>Investments Value</th>
            <th>Investments Increase</th>
            <th>Net</th>
          </tr>
        </thead>
      <tbody>
          {rows}
    </tbody>
    </table>
    );
  
}

export default InvestmentProjectionTable;