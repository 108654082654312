import * as React from 'react';
import {Transaction, CategorisedTransaction} from '../types';

const PropertyProjectionRow = (props: any) => {

    const budgetData = props.budgetData;

    return (
      <tr>
        <td>{props.year}</td>
        <td className = "money">{Math.round(Number(props.property_value)).toLocaleString('en-AU', {style: 'currency', currency: 'AUD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
        <td className = "money">{Number(props.loan_value).toLocaleString('en-AU', {style: 'currency', currency: 'AUD', maximumFractionDigits: 0, minimumFractionDigits: 0})}</td>
        <td className = "money">{Number(props.income).toLocaleString('en-AU', {style: 'currency', currency: 'AUD', maximumFractionDigits: 0, minimumFractionDigits: 0})}</td>
        <td className = "money">{Number(props.spending).toLocaleString('en-AU', {style: 'currency', currency: 'AUD', maximumFractionDigits: 0, minimumFractionDigits: 0})}</td>
        <td className = "money">{Number(props.compulsory_payment).toLocaleString('en-AU', {style: 'currency', currency: 'AUD', maximumFractionDigits: 0, minimumFractionDigits: 0})}</td>
        <td className = "money">{Number(props.yearly_payment).toLocaleString('en-AU', {style: 'currency', currency: 'AUD', maximumFractionDigits: 0, minimumFractionDigits: 0})}</td>
        <td className = "money">{Number(props.offset).toLocaleString('en-AU', {style: 'currency', currency: 'AUD', maximumFractionDigits: 0, minimumFractionDigits: 0})}</td>
        <td className = "money">{Number(props.ownedShare).toLocaleString('en-AU', {maximumFractionDigits: 4, minimumFractionDigits: 0})}</td>
      </tr>
    );
  
}

export default PropertyProjectionRow;