import './App.css';
import * as React from 'react';
import * as Bootstrap from 'react-bootstrap'
import {useState} from 'react';

import {Budget} from './types';





function BudgetBar(props: {budgets: Budget[], selectedBudget: number, changeBudget: any}) {

  function changeBudget(number: number)
  {
    props.changeBudget(number);
  }

  function newBudget(number: number)
  {
    props.changeBudget(-1);
  }

  function deleteBudget(number: number)
  {
    
    props.changeBudget(-2);
  }


    let rows: JSX.Element[] = [];

    let x = 0;
    props.budgets.forEach((budget) => {



      var buttonVariant = "primary"
      if (props.selectedBudget === x)
      {
        buttonVariant = "success";
      }

        

      let number = x;
      
      if (props.selectedBudget != x)
      rows.push(
        <Bootstrap.Button variant={buttonVariant} onClick={(() => {changeBudget(number)})}>{x}</Bootstrap.Button>
      )
      else
      rows.push(
        <Bootstrap.Button size="lg" variant={buttonVariant} onClick={(() => {changeBudget(number)})}>{x}</Bootstrap.Button>
      );
      x++;
      })


  return (

  <div>
    <div>
    {rows}
    <Bootstrap.Button variant={"secondary"} onClick={(() => {newBudget(-1)})}>+</Bootstrap.Button>
    </div>
    <div>
    <Bootstrap.Button variant={"secondary"} onClick={(() => {deleteBudget(props.selectedBudget)})}>DEL ({props.selectedBudget})</Bootstrap.Button>
    
    <Bootstrap.Button variant={"secondary"} onClick={(() => {changeBudget(props.selectedBudget)})}>Save ({props.selectedBudget})</Bootstrap.Button>
    </div> 
  </div>
  );

}

export default BudgetBar;
