import * as React from 'react';
import {useState, useEffect} from 'react';
import {Transaction, TransactionPeriod, CategorisedTransaction, Data, Period, HelpDebt, Person, Person2, AccountBalance, BudgetCategory, Budget, BudgetWithTransactions} from './types';
import Login from './Login';
import ResetPassword from './ResetPassword';
import MenuBar from './MenuBar';
import BudgetBar from './BudgetBar';
import BudgetApp from './BudgetView/BudgetApp';
import InvestmentsApp from './InvestmentsView/InvestmentApp2';
import PropertyApp from './PropertyView/PropertyApp';
import AddInvestmentApp from './AddInvestmentView/AddInvestmentApp';
import UploadTransactionsApp from './UploadTransactionsView/UploadTransactionsApp';
import ManageTransactionsApp from './ManageTransactionsView/ManageTransactionsApp';
import { convertGrossToNet } from './TaxFunctions';

var loaded = false;

const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

function App() {

const [succesful_login, setSuccesfulLogin] = useState(false);
const [activePage, setActivePage] = useState("Budget");
const [default_budget_with_transactions, setDefaultBudgetWithTransactions] = useState<BudgetWithTransactions>();
const [transactions_master, setTransactionsMaster] = useState<any[]>([]);
var [income_tax_brackets, setIncomeTaxBrackets] = useState<any[]>([]);
var [help_debt, setHelpDebt] = useState<any[]>([]);
var [help_tax_brackets, setHelpTaxBrackets] = useState<any[]>([]);
const [investment_transactions, setInvestmentTransactions] = useState<any[]>([]);

const [budgets, setBudgets] = useState<Budget[]>([]);
const [activeBudget, setActiveBudget] = useState(0);

const [transactions_unprocessed, setTransactionsUnprocessed] = useState<any[]>([]);
const [categories, setCategories] = useState<string[]>();

const [investment_budget, setInvestmentBudget] = useState<{category: string, amount: number}[]>([]);
const [property_budget, setPropertyBudget] = useState<{category: string, amount: number}[]>([]);
const [people, setPeople] = useState<Person[]>([]);
const [peopleFYStart, setPeopleFYStart] = useState<Person[]>([]);

const [default_owners, setDefaultOwners] = useState<Person2[]>([]);

const [uname, setUName] = useState("");
const [pword, setPWord] = useState("");

const [all_transactions, setAllTransactions] = useState<Transaction[]>();

const [accounts, setAccountBalances] = useState<AccountBalance[]>([]);

var accounts_temp: AccountBalance[] = [];
var default_budget_with_transactions_temp: BudgetWithTransactions;
const [budget_with_transactions, setBudgetWithTransactions] = useState<BudgetWithTransactions>();

var newBudgetCreated = -1;

// Budget App

var [budgetRowData, setBudgetRowData] = useState<CategorisedTransaction[]>();

const [freq, setFreq] = useState('Annually');

let date = new Date();
let year = date.getFullYear();
let month = date.getMonth();
let period_name = "";
if (month < 6)
  period_name = (year-1)  + " - " +  (year)
else
  period_name = (year)  + " - " +  (year+1)

const [period, setPeriod] = useState(period_name);

var [pickablePeriods, setPickablePeriods] = useState(GetPickablePeriods(transactions_master, freq));

function GetBudgetRowData(budget_master: BudgetWithTransactions, transactions_master: TransactionPeriod[], 
  help_debt: HelpDebt[], help_tax_brackets: any[], income_tax_brackets: any[], period: string, freq: string )
{
let budgetRowData: CategorisedTransaction[] = [];

budget_master.categorisedTransactions.forEach((category) =>{

let category_transactions: Transaction[] = [];

transactions_master.forEach((transactionPeriod)=>{

  if (transactionPeriod.financial_year === period || transactionPeriod.period === period){
    
    transactionPeriod.trans.forEach((transaction) => {

    if (transaction.category === category.category)
    category_transactions.push({
      id: transaction.id,
      date: transaction.date,
      category: transaction.category,
      credit: transaction.credit,
      debit: transaction.debit,
      details: transaction.details,
      account: transaction.account
    });
    });

}

});

let amt = 0;
let category_budget = 0;
for (var budgetCategory of budget_master.budget.budgetCategories)
{
  if (budgetCategory.category === category.category)
    category_budget = budgetCategory.amount;
}

if (category.category === "Samuel's Salary" || category.category == "Mel's Salary")
{
  let person = '';
  if (category.category === "Samuel's Salary")
    person = "Samuel";
  else if (category.category === "Mel's Salary")
    person = "Mel";
  amt = convertGrossToNet(category_budget, income_tax_brackets, Number(help_debt.find((p)=> p.person === person)?.help_debt!), help_tax_brackets);
}
else
{
    amt = category_budget;
}
  

if (freq === "Annually")
    amt = amt;
  else if (freq === "Monthly")
    amt = amt/12;
  else if (freq === "Fortnightly")
    amt = amt/365*14
  else if (freq === "Weekly")
    amt = amt/365*7



budgetRowData.push({
  category: category.category,
  budgeted_amount: amt,
  transactions: category_transactions
});

});


return budgetRowData;
}

const handleClick = (event: any) =>{
  let freq:string = event.target.value;
  setFreq(freq);

  let pickable_periods = GetPickablePeriods(transactions_master, freq);
  setPickablePeriods(pickable_periods);
  setPeriod(pickable_periods[pickable_periods.length-1]);

  let brd = GetBudgetRowData(budget_with_transactions!, transactions_master, help_debt, help_tax_brackets, income_tax_brackets, pickable_periods[pickable_periods.length-1], freq);
  setBudgetRowData(brd);

};

const handlePeriodChange = (event: any) =>{
  let period:string = event.target.value;
  setPeriod(period);
  let brd = GetBudgetRowData(budget_with_transactions!, transactions_master, help_debt, help_tax_brackets, income_tax_brackets, period, freq);
  setBudgetRowData(brd);

};

function GetPickablePeriods(transactions_master: TransactionPeriod[], freq: string) : string[]
{
  let pickable_periods: string[] = [];

  

  if (freq === "Monthly")
  {
    transactions_master.forEach ((period) => {
      pickable_periods.push(period.period);
    });
  
  }
  else if (freq === "Annually")
  {
     transactions_master.forEach(period => {
      let fy = period.financial_year;

      if (!pickable_periods.includes(fy))
      {
        pickable_periods.push(fy);
      }
    });


  }

  return pickable_periods;
}


// Budget App


function SaveData(data: Data)
{
  console.log("SaveData");
  console.log(data);
  while(transactions_master.length > 0){transactions_master.pop();}
  while(investment_budget.length > 0){investment_budget.pop();}
  while(property_budget.length > 0){property_budget.pop();}
  while(people.length > 0){people.pop();}
  while(peopleFYStart.length > 0){peopleFYStart.pop();}
  while(budgets.length > 0){budgets.pop();}
  while(default_owners.length > 0){default_owners.pop();}
  
  let budgetNumbers: number[] = [];


  data.budget.forEach(bud =>
  {
    if (!budgetNumbers.includes(bud.budget))
      budgetNumbers.push(bud.budget);
  });
  


  budgetNumbers.forEach(budNumber=>
  {
    let thisBudget: Budget = {budgetCategories: []};
    let budgetcats: string[] = [];

    data.budget.forEach(bud =>
    {
      // Get all budget categories
      if (bud.budget === budNumber && !budgetcats.includes(bud.category))
        budgetcats.push(bud.category);
    });

    // Work out the relevant instance of a budget category by checking the dates
    budgetcats.forEach(budCat =>{
      let todaysDate: Date = new Date();
      let latestDate: Date = new Date();

      latestDate.setFullYear(latestDate.getFullYear() - 100);
      data.budget.forEach(bud =>
      {
        let comparisonDate: Date = new Date(bud.date);
        
        if (bud.category === budCat && comparisonDate < todaysDate && comparisonDate > latestDate && budNumber === bud.budget)
        {
          latestDate = comparisonDate;
        }
          
      });

      
      data.budget.forEach(bud =>
        {
          let comparisonDate: Date = new Date(bud.date);
          if (bud.category === budCat && bud.budget === budNumber)
          {
            
            if (comparisonDate.getDate() === latestDate.getDate() && comparisonDate.getMonth() === latestDate.getMonth() && comparisonDate.getFullYear() === latestDate.getFullYear())
            {
              thisBudget.budgetCategories.push(bud);
            }
          }
            
        }); 

    });
    budgets.push(thisBudget);  
  });

  for (let x = 0; x<budgets.length; x++)
  {
    budgets[x] = adjustBudgetForPeriods(budgets[x]);
    budgets[x].budgetCategories.sort((a,b) => b.amount - a.amount);
    
  };


  accounts_temp = [];

  setAccountBalances([]);


  //console.log(data);

  let obfuscate = false;

  setIncomeTaxBrackets(data.income_tax);
  income_tax_brackets = data.income_tax;

  setHelpDebt(data.help_debt);
  help_debt = data.help_debt;

  setHelpTaxBrackets(data.help_tax);
  help_tax_brackets = data.help_tax;

  setTransactionsUnprocessed(data.unprocessed_transactions);
  setCategories(data.categories);

  setInvestmentTransactions(data.investment_transactions);

  setAllTransactions(data.transactions);

  if (newBudgetCreated != -1)
  {
    setActiveBudget(newBudgetCreated);
    newBudgetCreated = -1;
  }
  

  
  for (var i = 2021; i <= Number(new Date().getFullYear()); i++)
  {
    for (var j = 0; j < (i < Number(new Date().getFullYear()) ? 12 : Number(new Date().getMonth())+1); j++)
    {
      let m = {
        period: i + " " + months[j],
        year: i,
        month: months[j],
        financial_year: j < 6 ? (i-1) + " - " + i : (i) + " - " + (i + 1),
        trans: []
      };
      
      
      transactions_master.push(m); 
    }
  }

  default_budget_with_transactions_temp = {budget: budgets[0], categorisedTransactions: []};



  let defaultOwnersTemp: Person2[] = [];

  default_budget_with_transactions_temp.budget.budgetCategories.forEach((budgetCategory) => {

    let amt = budgetCategory.amount;


    var transactions: CategorisedTransaction = {category: budgetCategory.category, budgeted_amount: 0, transactions: []};
    default_budget_with_transactions_temp!.categorisedTransactions.push(transactions);

    

    //investment stuff
    if (budgetCategory.person === null)
    {
    investment_budget.push({
      category: budgetCategory.category,
      amount: amt
    })

      if (budgetCategory.category !== "Rent")
        property_budget.push({
          category: budgetCategory.category,
          amount: amt
        })
    }
    else{
      let value = 0;
      let hd = 0;
      let valueFYStart = 0;

      for (let i = 0; i < data.help_debt.length; i++)
      {
        //value = 
      }

      if (budgetCategory.category === "Samuel's Salary")
      {
        value = data.investments[0].samuel_value;
        hd = data.help_debt[1].help_debt;

        if (data.investments_fy[0] != undefined)
            valueFYStart = data.investments_fy[0].samuel_value;
        else
            valueFYStart = 0;
      }
      else if (budgetCategory.category === "Mel's Salary")
      {
        value = data.investments[0].mel_value;
        hd = data.help_debt[0].help_debt;

        if (data.investments_fy[0] != undefined)
          valueFYStart = data.investments_fy[0].mel_value;
        else
          valueFYStart = 0;
      }


      let person = {name: budgetCategory.category, gross_salary: amt, value: value, help_debt: hd, net_salary: 0, net_total: 0, capital_gains: 0, net_total_minus_spending: 0, investment_gain: 0}
      people.push(person);

      let personFYStart = {name: budgetCategory.category, gross_salary: amt, value: valueFYStart, help_debt: hd, net_salary: 0, net_total: 0, capital_gains: 0, net_total_minus_spending: 0, investment_gain: 0}
      peopleFYStart.push(personFYStart);

      let personHelpDebt: number = 0;

      for (let x = 0; x < data.help_debt.length; x++)
      {
        let help = data.help_debt[x];

        if (help.person === budgetCategory.person)
        {
          personHelpDebt = help.help_debt;
          break;
        }

        if (x === data.help_debt.length-1)
          throw new Error("No help debt found for " + budgetCategory.person + ".");
          
      }

      data.help_debt.forEach((help)=>{
        if (help.person === budgetCategory.person)
          personHelpDebt = help.help_debt;
      });
      
      
      defaultOwnersTemp.push({name: budgetCategory.person, helpDebt: personHelpDebt});

    };
    
    setDefaultOwners(defaultOwnersTemp);

  });

  data.transactions.forEach((transaction: Transaction) => {

    if (transaction.category === "Manual/Split Up")
      return;

    let date = new Date(transaction.date);
    let year = date.getFullYear();
    let month = months[date.getMonth()];
    let period_name = year + " " + month;


    if (transaction.category != "Manual/Split Up")
    {
      let found = false;
      for (var act in accounts_temp)
      {
        if (accounts_temp[act].name === transaction.account)
        {
          accounts_temp[act].value += Number(transaction.credit);
          accounts_temp[act].value -= Number(transaction.debit);
          found = true;
          break;
        }

      }

      if (!found)
      {
        accounts_temp.push({name: transaction.account, value: (Number(transaction.credit) - Number(transaction.debit)), valuelastFY: 0, owner: default_owners, isInvestment: false});
      }
        

    }

    transactions_master.find(o => o.period === period_name)?.trans.push(transaction);

    console.log(transaction.category);
    default_budget_with_transactions_temp!.categorisedTransactions.find(o => o.category === transaction.category)!.transactions.push(transaction);
    
    
  });  

  data.investments_fy.forEach(fy=>{
      accounts_temp.push({name: "Etoro Samuel", value: Number(data.investment_transactions[0].samuel_value), valuelastFY: fy.samuel_value, owner: [{ name: "Samuel", helpDebt: 0}], isInvestment: true});
      accounts_temp.push({name: "Etoro Mel", value: Number(data.investment_transactions[0].mel_value), valuelastFY: fy.mel_value, owner: [{ name: "Mel", helpDebt: 0}], isInvestment: true});
  });

  
  

  setAccountBalances(accounts_temp);
  setDefaultBudgetWithTransactions(default_budget_with_transactions_temp);

  let pickable_periods = GetPickablePeriods(transactions_master, freq);
  setPickablePeriods(pickable_periods);
  

  setBudgetWithTransactions(JSON.parse(JSON.stringify(default_budget_with_transactions_temp)));
  

  
}
// End Save Data

    function adjustBudgetForPeriods(budgetToAdjust: Budget) : Budget
    { 
      let b: Budget = JSON.parse(JSON.stringify(budgetToAdjust));

      for (let x = 0; x < b.budgetCategories.length; x++)
      {
        b.budgetCategories[x] = adjustCategoryForPeriod(b.budgetCategories[x]);
      }

      return b;
    }

    function adjustCategoryForPeriod(catToAdjust: BudgetCategory) : BudgetCategory
    {
      let cat: BudgetCategory = JSON.parse(JSON.stringify(catToAdjust));

      let amt = 0;
      if (cat.frequency === "anually")
        amt = cat.amount;
      else if (cat.frequency === "monthly")
        amt = cat.amount*12;
      else if (cat.frequency === "fortnightly")
        amt = cat.amount/14*365;
      else if (cat.frequency === "weekly")
        amt = cat.amount/7*365;
  
        cat.amount = amt;

        return cat;
    }

    function changeBudgetAmount(e: any, changedCategory: string, value: any)
    {      

      budgets[activeBudget].budgetCategories.forEach((budgetCat) =>{
        console.log(budgets[activeBudget].budgetCategories);
      
        
        if (budgetCat.category === changedCategory)
        {
          budgetCat.amount = Number(value.replace(/[^0-9.-]+/g,""));
          budgetCat.frequency = freq.toLocaleLowerCase();
          if (budgetCat.frequency == "annually")
            budgetCat.frequency = "anually";
          budgetCat.wasChanged = true;
          
          budgetCat.amount = Number(budgetCat.amount.toFixed(2));
            

          
        }
      });

      
      let temp_budget_with_transactions: BudgetWithTransactions = default_budget_with_transactions!;
      temp_budget_with_transactions!.budget = JSON.parse(JSON.stringify(budgets[activeBudget]));
      temp_budget_with_transactions = JSON.parse(JSON.stringify(temp_budget_with_transactions));
      setBudgetWithTransactions(temp_budget_with_transactions);
      
    } 


      const changeView = (event: any, page: string) =>{
        setActivePage(page);
      };

      const logout = (event: any) =>{
        loaded = false;
        setSuccesfulLogin(false);
      };

      function TriggerLoad(username:string, password:string)
      {
        setUName(username);
        setPWord(password);


        const formData:any = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        console.log("TriggerLoad");
        fetch("https://app.sholmes.org/api_get_budget.php", {
          method: "POST",
          redirect: 'follow',
          body: new URLSearchParams(formData)
        })
        .then(resp => resp.json())
        .then(data => SaveData(data))
      }

      const CreateNewBudget = () => {

        const formData:any = new FormData();
        formData.append('username', uname);
        formData.append('password', pword);

        formData.append('addBudget', true);
        console.log("CreateNewBudget");
        fetch("https://app.sholmes.org/api_post_adjust_budget.php", {
          method: "POST",
          redirect: 'follow',
          body: new URLSearchParams(formData)
        })
        .then(resp => resp.json())
        .then (resp => newBudgetCreated = resp)
        .then(data => TriggerLoad(uname, pword));
        //.then(data => setActiveBudget(Number(data)))

      };

      const ChangeBudget = (budgetN: number) =>{

        //console.log("changing Budget");
        //console.log(budgetN);
        if (budgetN === -1)
        {
          CreateNewBudget();
        }
        else if (budgetN === -2)
        {
          DeleteBudget(activeBudget);
        }
        else if (activeBudget === budgetN)
        {
          saveBudget(budgetN, budgets[budgetN], uname, pword);

        }
        else
        {
          let temp_budget_with_transactions: BudgetWithTransactions = default_budget_with_transactions!;
          temp_budget_with_transactions!.budget = JSON.parse(JSON.stringify(budgets[budgetN]));
  
          setActiveBudget(budgetN);
        }


      };

      function DeleteBudget(activeBudget: number) {
        const formData:any = new FormData();
        formData.append('username', uname);
        formData.append('password', pword);

        formData.append('deleteBudget', JSON.stringify(activeBudget));
        
        console.log("DeleteBudget");
        fetch("https://app.sholmes.org/api_post_adjust_budget.php", {
          method: "POST",
          redirect: 'follow',
          body: new URLSearchParams(formData)
        })
        .then(resp => resp.json())
        .then(resp => setActiveBudget(0))
        .then(data => TriggerLoad(uname, pword));
      }

      function saveBudget(budgetId: number, budget: Budget, username:string, password:string)
      {
        const formData:any = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        let budgetSave: {id:number, budget: Budget} = {id:budgetId, budget:budget};

        let modifiedCats: BudgetCategory[] = [];

        //console.log(budgets[budgetId]);
        budgets[budgetId].budgetCategories.forEach((budgetCat) =>{
          if (budgetCat.wasChanged === true)
          {
            modifiedCats.push(budgetCat);
          }
        });

        if (modifiedCats.length === 0)
        {
          saveBudgetComplete(undefined, budgetId);
          return;
        }
    
        formData.append('modifiedCats', JSON.stringify(modifiedCats));
        
        console.log("saveBudget");
        fetch("https://app.sholmes.org/api_post_adjust_budget.php", {
          method: "POST",
          redirect: 'follow',
          body: new URLSearchParams(formData)
        })
        .then(function(respo: any) {
          if (respo.ok) {
            
          } else {};
    
          return respo;
        })
        .then(resp => resp.json())
        .then(response => saveBudgetComplete(response, budgetId))
      }

      function saveBudgetComplete(response: any, budgetId: number)
      {
        budgets[budgetId].budgetCategories.forEach((budgetCat) =>{
          budgetCat.wasChanged = false;
        });
        console.log(response);
      }



      useEffect(() => {
        if (default_budget_with_transactions != undefined)
        {
          let temp_budget_with_transactions: BudgetWithTransactions = default_budget_with_transactions!;
          temp_budget_with_transactions!.budget = JSON.parse(JSON.stringify(budgets[activeBudget]));
          temp_budget_with_transactions = JSON.parse(JSON.stringify(temp_budget_with_transactions));
          setBudgetWithTransactions(temp_budget_with_transactions);
        }
        
        
      }, [activeBudget]);

      useEffect(() => {
        if (budget_with_transactions != undefined)
        {
          setBudgetRowData(GetBudgetRowData(budget_with_transactions!, transactions_master, help_debt, help_tax_brackets, income_tax_brackets, period, freq));

          loaded = true;
          console.log("Load Complete");
        }
        
      }, [budget_with_transactions]);

      useEffect(() => {
      }, [budgetRowData]);
      
      
 
    if (!succesful_login)
    return(
      <div className="App">
        <Login setSuccesfulLogin={setSuccesfulLogin} triggerLoad={TriggerLoad}></Login>
      </div>
    )
    else if (loaded)
    {
      
    return (
      <div className="App container-fluid">
        <MenuBar changeView = {changeView} active={activePage} logout={logout} reload = {TriggerLoad} username={uname} password={pword} ></MenuBar>
        <BudgetBar budgets= {budgets} selectedBudget={activeBudget} changeBudget={ChangeBudget}></BudgetBar>

        { (activePage === "Budget") && 
        <BudgetApp handleClick={handleClick} handlePeriodChange={handlePeriodChange} pickablePeriods={pickablePeriods} selectedBudget={activeBudget} budget_with_transactions = {budget_with_transactions!} transactions_master={transactions_master} help_debt={help_debt} 
        help_tax_brackets={help_tax_brackets} income_tax_brackets={income_tax_brackets} account_balances={accounts}
        people={people}
        
        budgetRowData = {budgetRowData!}
        freq = {freq}
        period={period}
        budgetChanged={changeBudgetAmount}

        ></BudgetApp>
        }
        { (activePage === "Investments") &&
        <InvestmentsApp data={investment_transactions} people2 = {default_owners} accounts = {accounts} investment_budget = {investment_budget} budget = {budgets[activeBudget]} people={people} peopleStartFY={peopleFYStart} help_tax_brackets={help_tax_brackets} income_tax_brackets={income_tax_brackets}></InvestmentsApp>
        }
        { (activePage === "Property") &&
        <PropertyApp accounts = {accounts} budget = {budgets[activeBudget]} selectedBudget={activeBudget} people2 = {default_owners} investment_budget = {property_budget} help_tax_brackets={help_tax_brackets} income_tax_brackets={income_tax_brackets}></PropertyApp>
        }
        { (activePage === "Add Investment") &&
        <AddInvestmentApp data={investment_transactions} username={uname} password={pword} reload={TriggerLoad}></AddInvestmentApp>
        }
        { (activePage === "Upload Transactions") &&
        <UploadTransactionsApp username={uname} password={pword} reload={TriggerLoad} unprocessed_transactions={all_transactions} budget_categories={categories}></UploadTransactionsApp>
        }
        { (activePage === "Manage Transactions") &&
        <ManageTransactionsApp username={uname} password={pword} reload={TriggerLoad} unprocessed_transactions={transactions_unprocessed} budget_categories={categories}></ManageTransactionsApp>
        }
        { (activePage === "Reset PW") &&
        <ResetPassword username={uname} password={pword} reload={TriggerLoad}></ResetPassword>
        }
      </div>
    )
    }
    else
    return (
    <div className="App"><h1>Loading...</h1></div>
    );
  
}

export default App;


