
import * as React from 'react';
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react';
import {useState} from 'react';
import BudgetRow from './budget_row';
import {Transaction, TransactionPeriod, CategorisedTransaction} from '../types';


const BudgetTable = (props: { 
      budgetRowData: CategorisedTransaction[]; 
      budgetPeriods: string[]; 
      freq: string;
      periodSelected: any ;
      periodValue: string;
      rowHover: any;
      rowUnHover: any;
      rowClick: any;
      selectedRow: string;
      budgetNumber: number;
      budgetChanged: any;
    }) =>  {
  
    const rows: JSX.Element[] = [];
    const periods: JSX.Element[] = [];
    const budgetRowData = props.budgetRowData;

    let totalBudgeted = 0;
    let totalBudgetedExpenses = 0;
    let totalBudgetedIncome = 0;
    let totalActual = 0;
    let totalActualExpenses = 0;
    let totalActualIncome = 0;
    
    budgetRowData.forEach((cat) => {


          totalBudgeted += Number(cat.budgeted_amount);

          if (Number(cat.budgeted_amount) < 0)
            totalBudgetedExpenses += Number(cat.budgeted_amount);
          else
            totalBudgetedIncome += Number(cat.budgeted_amount);
        
        cat.transactions.forEach((trans) => {
          if (trans.category != "Invested")
          {
            totalActual += Number(trans.credit) - Number(trans.debit);
            if (cat.budgeted_amount <= 0)
              totalActualExpenses += Number(trans.credit) - Number(trans.debit);
            else
              totalActualIncome += Number(trans.credit) - Number(trans.debit);
          }
          
        });

        rows.push(
          <BudgetRow
            key={cat.category}
            budgetData={cat}
            rowHover={props.rowHover}
            rowUnHover={props.rowUnHover}
            rowClick={props.rowClick}
            selected={props.selectedRow === cat.category ? true : false}
            budgetChanged={props.budgetChanged}
          />
        );
    
    })

    props.budgetPeriods.forEach((period) =>{
      periods.push(
        <option key={period} value={period} >{period}</option>
      )
      
    })

    return (
      <table className="table table-striped table-bordered table-hover ">

        <thead>
          <tr>
            <th>Category</th>
            <th>Amount Budgeted<br/> ({props.freq})</th>
            <th> Amount spent/earned for period:<br/>
              { (props.freq === "Monthly" || props.freq === "Annually") &&
                <select value={props.periodValue} onChange={props.periodSelected}>
                  {periods}
                </select>
              }
            </th>
          </tr>
        </thead>
      <tbody>
          {rows}
          <tr>
            <td className = "totalrow" >Income</td>
            <td className="money totalrow">{totalBudgetedIncome.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
            <td style={{ backgroundColor: totalActualIncome > totalBudgetedIncome ? "#c7ffbf" : "#f7adad" }} className="money totalrow">{totalActualIncome.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
          </tr>
          <tr>
            <td className = "totalrow" >Expenses</td>
            <td className="money totalrow">{totalBudgetedExpenses.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
            <td style={{ backgroundColor: totalActualExpenses > totalBudgetedExpenses ? "#c7ffbf" : "#f7adad" }} className="money totalrow">{totalActualExpenses.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
          </tr>
          <tr>
            <td className = "totalrow" >Total</td>
            <td className="money totalrow">{totalBudgeted.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
            <td style={{ backgroundColor: totalActual > totalBudgeted ? "#c7ffbf" : "#f7adad" }} className="money totalrow">{totalActual.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
          </tr>  
    </tbody>
    </table>
    );
  
}

export default BudgetTable;