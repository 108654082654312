import './App.css';
import * as React from 'react';
import {useState} from 'react';


function ResetPassword(props:{username: string, password: string, reload: any}) {

    const [oldPassword, setOldPassword] = useState(""); 
    const [password, setPassword] = useState(""); 
    const [password2, setPassword2] = useState("");

    const [error, setError] = useState("");


function CheckValidFields()
{
    if (password != password2)
    {
        setError("Passwords do not match!");
        return false;
    }
        

    setError("");
}


const login = (event: any) =>{
  event.preventDefault();

  if (CheckValidFields() === false)
  return;

  const formData:any = new FormData();

  formData.append('username', props.username);
  formData.append('password', props.password);
  formData.append('newPassword', password);
  formData.append('oldPassword', oldPassword);

  fetch("https://app.sholmes.org/api_reset.php", {
    method: "POST",
    redirect: 'follow',
    body: new URLSearchParams(formData)
  })

   .then(resp => resp.json())
   .then(response => CheckReset(response));
};

function CheckReset(response: any)
{
    if (response === "success" )
        setError("Password changed!");
    else
        setError(response);
}


  return (
<div className="Login container">
    <div className="row">
        <div className="col-lg-4"></div>
        <div className="col-lg-4">
            <h2>Password Reset</h2>
            {(error != "") &&
            <div className="alert alert-danger">
            {error}
            </div>
            }
            <form className="commentForm" onSubmit={login}>
            <div className="form-outline">
                <label htmlFor="oldPassword">Old Password:</label>
                <input id="oldPassword" name="oldPassword" type="password" className="form-control" onChange={(e) => setOldPassword(e.target.value)} placeholder="Current Password"></input>
            </div>
            <div className="form-outline">
                <label htmlFor="password">Password:</label>
                <input id="password" name="password" type="password" className="form-control" onChange={(e) => setPassword(e.target.value)} placeholder="Password"></input>
            </div>
            <div className="form-outline">
                <label htmlFor="password2">Confirm Password:</label>
                <input id="password2" name="password2" type="password" className="form-control" onChange={(e) => setPassword2(e.target.value)} placeholder="Confirm Password"></input>
            </div>
            <br/>
            <button type="submit" className="btn btn-primary" onMouseDown={login}>Reset</button>
            </form>
        </div>
        <div className="col-lg-4"></div>
    </div>
</div>
  );

}

export default ResetPassword;
