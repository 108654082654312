import * as React from 'react';
import {Transaction, CategorisedTransaction} from '../types';
import {useState} from 'react';

const BudgetRow = (props: { budgetData: CategorisedTransaction; rowHover: any; rowUnHover: any; rowClick: any; selected: boolean, budgetChanged: any}) => {

  function commitChange(e: any)
  {
    props.budgetChanged(e, budgetData.category, e.target.value);
    //setValue(Number(e.value).toFixed(2));
    setFocused(false);

  }

    const budgetData = props.budgetData;
    const [focused, setFocused] = useState(false);
    //const [value, setValue] = useState(Number(budgetData.budgeted_amount));



    let total = 0;
    budgetData.transactions.forEach((t) =>{
      total += t.credit - t.debit;
    });

    let classes = "show-inline ";
    if (props.selected)
      classes += " bg-success";


    return (
      
      <tr key={budgetData.category} className={classes} 
      onMouseEnter={((event) => props.rowHover(event, budgetData.category))} 
      onMouseLeave={props.rowUnHover} 
      onMouseDown={((event) => props.rowClick(event, budgetData.category))}>
        <td>{budgetData.category}</td>

        <td className = "money">
          {(!focused) &&
          <span onClick={(e)=>setFocused(true)}> {Number(budgetData.budgeted_amount).toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}  </span>
          }
          {(focused) &&
          <input autoFocus type = "number" defaultValue={Number(budgetData.budgeted_amount).toFixed(2)} onBlur={(e)=>commitChange(e)}/>
          }
        </td>
        <td className = "money">{total.toLocaleString('en-AU', {style: 'currency', currency: 'AUD'})}</td>
      </tr>
    );
  
}

export default BudgetRow;