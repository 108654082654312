import './App.css';
import * as React from 'react';
import {useState} from 'react';


function Login(props:{setSuccesfulLogin: any, triggerLoad: any}) {

    const [username, setUsername] = useState(""); 
    const [password, setPassword] = useState(""); 
    const [error, setError] = useState("");


const CheckLogin = (response: string) =>{
    console.log(response);
    if (response === "success")
    {
        props.setSuccesfulLogin(true);
        props.triggerLoad(username, password);
    }
    else
    {
        setError("Incorrect Details");
        //alert("Incorrect Details");
    }
};

const login = (event: any) =>{
  event.preventDefault();

  const formData:any = new FormData();

  formData.append('username', username);
  formData.append('password', password);

  fetch("https://app.sholmes.org/api_login.php", {
    method: "POST",
    redirect: 'follow',
    body: new URLSearchParams(formData)
  })

   .then(resp => resp.json())
   .then(response => CheckLogin(response));
};


  return (
<div className="Login container">
    <div className="row">
        <div className="col-lg-4"></div>
        <div className="col-lg-4">
            <h1>Samuel's Finance App</h1>
            <h1>Login</h1>
            {(error != "") &&
            <div className="alert alert-danger">
            {error}
            </div>
            }
            <form className="commentForm" onSubmit={login}>
            <div className="form-outline">
                <label htmlFor="username">Username:</label>
                <input id="username" name="username" type="text" className="form-control" onChange={(e) => setUsername(e.target.value)} placeholder="Username"></input>
            </div>
            <div className="form-outline">
                <label htmlFor="password">Password:</label>
                <input id="password" name="password" type="password" className="form-control" onChange={(e) => setPassword(e.target.value)} placeholder="Password"></input>
            </div>
            <br/>
            <button type="submit" className="btn btn-primary" onMouseDown={login}>Login</button>
            </form>
        </div>
        <div className="col-lg-4"></div>
    </div>
</div>
  );

}

export default Login;
